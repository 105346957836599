import { getApiOrigin } from "./context";
import * as utils from "../services/utilities";
export default class NoticeService {
  constructor(headers) {
    this.headers = headers;
    this.alreadyViewed = [];
  }
  addAlreadyViewed(notices) {
    utils.log.api(`notice.addAlreadyViewed()`, notices);
    utils.assert(notices, "notices required");

    notices.forEach((notice) => {
      utils.pushIfNotExist(this.alreadyViewed, notice);
    });
  }

  async get(id) {
    utils.log.api(`notice.get(${id})`);
    utils.assert(id, "id required");

    const remoteUrl = `${getApiOrigin()}/notice/${id}`;
    const response = await fetch(remoteUrl, {
      method: "GET",
      headers: this.headers,
    });

    const notice = await response.json();
    utils.log.debug(`notice.get(${id}) result`, notice);
    return notice;
  }
  async getBySection(section, mode, id, filterOutResponses = true) {
    utils.log.api(`notice.getBySection(${section})`);
    utils.assert(section, "section required");
    utils.assert(
      mode === "edit" || mode === "view",
      "mode required(edit or view)"
    );

    const remoteUrl = `${getApiOrigin()}/notice/GetBySection/${section}?mode=${mode}&id=${(id ??=
      "")}&filterOutResponses=${filterOutResponses}`;
    const response = await fetch(remoteUrl, {
      method: "GET",
      headers: this.headers,
    });

    if (!response.ok) throw new Error("Failed to get notices by section.");
    const notices = await response.json();
    utils.log.debug(`notice.getBySection(${section}) result`, notices);
    return notices;
  }
  async add(notice) {
    // TODO: Add notice type checking (proptypes)
    utils.log.api(`notice.add(${notice.code})`, notice);
    utils.assert(notice, "notice required");
    const remoteUrl = `${getApiOrigin()}/notice/add`;
    const response = await fetch(remoteUrl, {
      method: "POST",
      headers: this.headers,
      body: JSON.stringify(notice),
    });
    if (!response.ok) throw new Error("Failed to add notice.", notice);
  }
  create(section) {
    const notice = {
      id: 0,
      section: section,
      scope: [],
      sort: 0,

      name: "",
      title: "",
      description: "",
      message: "",
      alwaysShow: true,

      effectiveDate: null,
      expirationDate: null,

      isAcknowledgementRequired: false,
      acknowledgementText: "Accept",
      errorText: null,
    };
    return notice;
  }
  async update(notice) {
    utils.log.api(`notice.update(${notice.id})`, notice);
    utils.assert(notice, "notice required");
    const remoteUrl = `${getApiOrigin()}/notice/update`;

    const response = await fetch(remoteUrl, {
      method: "POST",
      headers: this.headers,
      body: JSON.stringify(notice),
    });
    if (!response.ok) throw new Error("Failed to update notice.", notice);
  }
  async remove(notice) {
    utils.log.api(`notice.remove(${notice.id})`, notice);
    utils.assert(notice, "notice required");
    const remoteUrl = `${getApiOrigin()}/notice/ById/${notice.id}`;

    const response = await fetch(remoteUrl, {
      method: "DELETE",
      headers: this.headers,
    });
    if (!response.ok) throw new Error("Failed to remove notice.", notice);
  }
  async acknowledgeCoordinator(notice) {
    utils.log.api(`notice.acknowledgeCoordinator(${notice.id})`, notice);
    utils.assert(notice, "notice required");
    const remoteUrl = `${getApiOrigin()}/notice/acknowledgement/coordinator?noticeId=${
      notice.id
    }`;

    const response = await fetch(remoteUrl, {
      method: "POST",
      headers: this.headers,
      body: JSON.stringify(notice),
    });
    if (!response.ok)
      throw new Error("Failed to acknowledge notice as coordinator.", notice);
  }
  async acknowledgeRecipient(notice) {
    utils.log.api(`notice.acknowledgeRecipient(${notice.id})`, notice);
    utils.assert(notice, "notice required");
    const remoteUrl = `${getApiOrigin()}/notice/acknowledgement/recipient?noticeId=${
      notice.id
    }`;

    const response = await fetch(remoteUrl, {
      method: "POST",
      headers: this.headers,
      body: JSON.stringify(notice),
    });
    if (!response.ok)
      throw new Error("Failed to acknowledge notice as recipient.", notice);
  }

  // responsesCoordinator
  // responsesRecipient
}
