import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { DialogActions } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Form from "../Form";

const useStyles = makeStyles((theme) => ({
  dialogActionsStyle: {
    [theme.breakpoints.down("sm")]: {
      flexWrap: "wrap",
      gap: "20px",
    },
  },
  iconStyle: {
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
}));

// const style = {
//   height: "500px",
// };

export default function MUIDialog({
  open,
  onClose,
  fullWidth = true,
  maxWidth = "md",
  PaperProps,
  title = "",
  description = "",
  children,
  actions,
  onSubmit,
}) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const classes = useStyles();
  return (
    <>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        fullWidth={fullWidth}
        onClose={onClose}
        maxWidth={maxWidth}
        PaperProps={PaperProps}
        aria-labelledby="responsive-dialog-title"
      >
        {title && (
          <DialogTitle
            id="responsive-dialog-title"
            style={{ padding: "16px 24px 0px 24px" }}
          >
            {title}
          </DialogTitle>
        )}
        <IconButton
          className={classes.iconStyle}
          aria-label="close"
          onClick={onClose}
          sx={(theme) => ({
            position: "absolute",

            right: 8,
            top: 8,

            color: theme.palette.grey[500],
          })}
        >
          <CloseIcon />
        </IconButton>

        {onSubmit ? (
          <Form onSubmit={onSubmit}>
            <DialogContent>
              {description && (
                <DialogContentText>{description}</DialogContentText>
              )}
              {children}
            </DialogContent>
            {actions && (
              <DialogActions className={classes.dialogActionsStyle}>
                {actions}
              </DialogActions>
            )}
          </Form>
        ) : (
          <>
            <DialogContent>
              {description && (
                <p
                // style={{ paddingTop: "0px", paddingBottom: "5px" }}
                >
                  {description}
                </p>
              )}
              {children}
            </DialogContent>

            {actions && (
              <DialogActions className={classes.dialogActionsStyle}>
                {actions}
              </DialogActions>
            )}
          </>
        )}
      </Dialog>
    </>
  );
}

MUIDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  fullWidth: PropTypes.bool,
  maxWidth: PropTypes.oneOf(["xs", "sm", "md", "lg", "xl"]),
};
