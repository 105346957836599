import React from "react";
//import { enqueueSnackbar, useSnackbar } from "notistack";
import { NoticeEditor } from "../components/Notice";
export const Notices = () => {
  /*     const { employeeService } = useContext(AuthContext);
    const { enqueueSnackbar } = useSnackbar();  */
  const sections = [
    "Splash",
    "EULA",
    "PrivacyPolicy",
    "WhatIsNew",
    "SystemUnavailable",
    "Logout",
  ];
  return <NoticeEditor sections={sections} editorHeight={450} />;
};
export default Notices;
