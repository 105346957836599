import { useAuth0 } from "@auth0/auth0-react";
import React, { useContext, useEffect, useRef, useState } from "react";
import { Link, useNavigate, NavLink } from "react-router-dom";
import { Add } from "@mui/icons-material";
import KeyIcon from "@mui/icons-material/Key";
import PersonIcon from "@mui/icons-material/Person";
import {
  AppBar,
  Avatar,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  MenuList,
  Stack,
  TextField,
  Button as MUIButton,
  Box,
  InputAdornment,
  useTheme,
  useMediaQuery,
  FormControl,
  Select,
} from "@mui/material";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import logo from "../assets/GPG_Logo.svg";
import logout from "../assets/logout.svg";
import orgPeople from "../assets/organization_people.svg";
import { AuthContext } from "../services/context";
import * as utils from "../services/utilities";
import { makeStyles } from "@mui/styles";
import Button from "./Button";
import { NAVBAR } from "../utils/contentData";
import NavBarToggleIcon from "../assets/NavBarTogglerIconNew.svg";
import MenuIcon from "@mui/icons-material/Menu";
import MenuOpenIcon from "@mui/icons-material/MenuOpen";
import Item from "antd/lib/list/Item";
import { getHeaders } from "../services/context";
import { enqueueSnackbar } from "notistack";
import Form from "./Form";
import EmployeeService from "../services/employee";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { StylesProvider } from "@material-ui/core/styles";
import LoginIcon from "@mui/icons-material/Login";
import SignUpIcon from "../assets/get started.svg";

const ROLE_PRIMARY_CORD = "PrimaryCoordinator";
const ROLE_DEPARTMENT_CORD = "DepartmentCoordinator";
const USE_CASE = "Use Case";
const RULE_TWENTY_TWO = "Rule 2202";
const UNIVERSITIES = "Universities";
const EMPLOYEES = "Employees";
const TDM = "Transport Demand Management";

const useStyles = makeStyles((theme) => ({
  root: {
    zIndex: 999,
    background: "#fff",
    boxShadow: "none",
    borderBottom: "1px solid #d7d7d7",
    height: "74px",
    maxWidth: "none",
    paddingLeft: "50px",
    paddingRight: "70px",
    flexDirection: "row",
  },
  textSwitch: {
    color: "#07070780",
    fontWeight: "600",
    lineHeight: "18px",
    paddingBottom: "2px",
    fontSize: "12px",
  },
  selectedOption: {
    color: "#070707",
    fontWeight: "600",
    lineHeight: "18px",
    fontSize: "14px",
    textTransform: "capitalize",
  },
  selectedOptionEmployee: {
    color: "#070707",
    fontWeight: "600",
    lineHeight: "18px",
    fontSize: "14px",
    textTransform: "capitalize",
  },
  AppBarContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    // zIndex: 99999999,
  },
  userMenuContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  loginGetStarted: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    gap: "10px",
  },
  container: {
    padding: 0,
  },
  logo: {
    marginRight: "14px",
  },
  // - Organisation Selector
  organisationSelector: {
    border: "1px solid #D6D6D6",
    padding: "4px 10px 4px 10px",
    borderRadius: "8px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: "10px",
    background: "#E9E9E9",
    cursor: "pointer",
    "@media (max-width: 767px)": {
      display: "none",
    },
  },
  hideEmployeeDetail: {
    "@media (max-width: 767px)": {
      display: "none",
    },
  },
  organisationSelectorLabel: {
    color: "#07070780",
    fontWeight: "600",
    fontSize: "12px",
    lineHeight: 1.3,
  },
  organisationSelectorValue: {
    color: "#070707",
    fontWeight: "600",
    fontSize: "14px",
    textTransform: "capitalize",
    lineHeight: 1.3,
  },

  cursorPointer: {
    cursor: "pointer",
  },
  userProfileRoleTitle: {
    color: "#000",
    fontSize: "12px",
    marginTop: "2px",
  },
  menuItemColor: {
    color: "#000",
  },
  addIcon: {
    border: "1px solid",
    borderColor: "#0F0F0F33",
    borderRadius: "4px",
  },
  text: {
    primary: "#173A5E",
    secondary: "#070707",
  },
  selectedItemColor: {
    color: theme.palette.primary.main,
  },
  navItems: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    gap: "20px",
    [theme.breakpoints.down("lg")]: {
      gap: "10px",
    },
  },
  isActiveLink: {
    border: "none",
    outline: "0px",
    color: "#0066f9",
  },
  isActive: {
    border: "none",
    outline: "0px",
    color: "#0066f9",
  },
  isActiveButton: {
    color: "#0066f9",
    whiteSpace: "nowrap",
  },
  isNotActive: {
    border: "none",
    whiteSpace: "nowrap",
    outline: "0px",
  },
  noOutline: {
    "&:focus": {
      outline: "none",
    },
  },
  noOutlineButton: {
    "&:focus": {
      outline: "none",
    },
  },
  noOutlineButtonProvisining: {
    "&:focus": {
      outline: "none",
    },
  },
}));

function currentUserRole(roles) {
  if (
    roles?.includes(ROLE_PRIMARY_CORD) &&
    roles?.includes(ROLE_DEPARTMENT_CORD)
  ) {
    return "Primary Coordinator";
  } else if (roles?.includes(ROLE_PRIMARY_CORD)) {
    return "Primary Coordinator";
  } else if (roles?.includes(ROLE_DEPARTMENT_CORD)) {
    return "Department Coordinator";
  } else {
    return null;
  }
}

export default function NavBar({ isSideBarOpen, handleToggleSideBar }) {
  const classes = useStyles();
  const navigate = useNavigate();
  const { user, isAuthenticated, loginWithRedirect, logout } = useAuth0();
  const { employee, token } = useContext(AuthContext);
  const [anchorEl, setAnchorEl] = useState(null);
  const [isProvisioningVisible, setIsProvisioningVisible] = useState(false);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const logoutWithRedirect = () => {
    logout({ logoutParams: { returnTo: window.location.origin } });
  };


  useEffect(() => {
    if (employee === null) return;
    if (employee && Object.keys(employee).length > 0) {
      setIsProvisioningVisible(false);
    } else {
      setIsProvisioningVisible(true);
    }
  }, [employee]);

  useEffect(() => {
    if (employee === null) return;
    if (isProvisioningVisible) {
      navigate("/provisioning");
    }
  }, [isProvisioningVisible, employee]);

  useEffect(() => {
    if (employee?.roles.includes("DepartmentCoordinator") && !employee?.plan) {
      navigate("/profile?view=plan");
    }
  }, [employee]);

  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.down("md"));

  const handleUseCase = (e) => {
    const currentSelectedVal = e.target.value;

    switch (currentSelectedVal) {
      case RULE_TWENTY_TWO:
        navigate("rule-book");
        break;
      case UNIVERSITIES:
        navigate("universities");
        break;
      case EMPLOYEES:
        navigate("employees");
        break;
      case TDM:
        navigate("transport-demand-management");
        break;

      default:
        navigate("/");
        break;
    }
  };

  return (
    <StylesProvider injectFirst>
      <AppBar
        component="nav"
        position="fixed"
        className={` ${classes.root} ${classes.AppBarContainer} `}
      >
        {/* <Container  className={[classes.container, classes.flex]}> */}
        <div className={classes.navItems}>
          <div style={{ position: "relative" }}>
            {isAuthenticated &&
              (isSideBarOpen ? (
                <MenuOpenIcon
                  onClick={handleToggleSideBar}
                  sx={{
                    height: "30px",
                    width: "30px",
                    color: "black",
                    cursor: "pointer",
                  }}
                />
              ) : (
                <MenuIcon
                  onClick={handleToggleSideBar}
                  sx={{
                    height: "30px",
                    width: "30px",
                    color: "black",
                    cursor: "pointer",
                  }}
                />
              ))}
          </div>

          <Link to="/">
            <img
              src={logo}
              width="auto"
              height="48"
              className={classes.logo}
              alt="GoPassGo Networks, LLC"
            />
          </Link>

          {!isAuthenticated ? (
            isMd ? (
              <></>
            ) : (
              NAVBAR.links.map((item) => {
                if (!item.condition && item.link !== "/use-case") {
                  return (
                    <NavLink
                      to={item?.link}
                      key={item?.link}
                      className={({ isActive }) =>
                        isActive ? classes.isActive : classes.isNotActive
                      }
                    >
                      {({ isActive }) => (
                        <MUIButton
                          className={`${classes.noOutlineButton} ${
                            isActive ? classes.isActiveButton : ""
                          }`}
                        >
                          {item.title}
                        </MUIButton>
                      )}
                    </NavLink>
                  );
                }
                if (item.link === "/use-case") {
                  return (
                    <FormControl fullWidth>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={""}
                        displayEmpty
                        label="Age"
                        renderValue={(selected) => {
                          if (selected === "") {
                            return <div>{USE_CASE}</div>;
                          }
                        }}
                        sx={{
                          ".MuiOutlinedInput-notchedOutline": {
                            border: "none",
                          },
                        }}
                        MenuProps={{
                          anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left",
                          },
                          transformOrigin: {
                            vertical: "top",
                            horizontal: "left",
                          },
                          PaperProps: {
                            style: {
                              marginTop: "8px",
                            },
                          },
                        }}
                        onChange={handleUseCase}
                      >
                        <MenuItem value={RULE_TWENTY_TWO}>
                          {RULE_TWENTY_TWO}
                        </MenuItem>
                        <MenuItem value={UNIVERSITIES}>{UNIVERSITIES}</MenuItem>
                        <MenuItem value={EMPLOYEES}>{EMPLOYEES}</MenuItem>
                        <MenuItem value={TDM}>{TDM}</MenuItem>
                      </Select>
                    </FormControl>
                  );
                }

                if (item?.condition === "provisioning") {
                  return (
                    <NavLink
                      to={item.link}
                      className={({ isActive }) =>
                        isActive ? classes.isActiveLink : null
                      }
                    >
                      <MUIButton className={classes.noOutlineButtonProvisining}>
                        {item.title}
                      </MUIButton>
                    </NavLink>
                  );
                }

                return null;
              })
            )
          ) : (
            <>
              <div
                className={` ${classes.organisationSelector}`}
                onClick={handleClick}
              >
                <Stack
                  direction={"row"}
                  spacing={2}
                  sx={{
                    display: "flex",
                    justifyContent: isMd ? "space-between" : "center",
                    alignItems: "center",
                    cursor: "pointer",
                    width: isMd ? "100%" : "auto",
                  }}
                >
                  <Item style={{ listStyle: "none" }}>
                    <img
                      src={orgPeople}
                      alt="GoPassGo Networks, LLC"
                      width="34"
                      height="34"
                    />
                  </Item>
                  <Item style={{ listStyle: "none" }}>
                    <Stack direction={"column"} sx={{ height: "full" }}>
                      <Box
                        sx={{
                          color: "#07070780",
                          fontWeight: "600",
                          lineHeight: "18px",
                          paddingBottom: "2px",
                          fontSize: "12px",
                        }}
                      >
                        Switch Organization :
                      </Box>
                      <Box className={classes.selectedOptionEmployee}>
                        {employee?.organization?.legalName}
                      </Box>
                    </Stack>
                  </Item>
                  <Item style={{ listStyle: "none" }}>
                    <Stack direction={"column"} spacing={-3.5}>
                      <Box sx={{ fontWeight: "bold", color: "black" }}>
                        <img src={NavBarToggleIcon} alt="toggle-icon" />
                      </Box>
                      <Box
                        sx={{
                          fontWeight: "bold",
                          transform: "rotate(-180deg)",
                          color: "black",
                        }}
                      >
                        <img src={NavBarToggleIcon} alt="toggle-icon" />
                      </Box>
                    </Stack>
                  </Item>
                </Stack>

                {isProvisioningVisible && (
                  <p route="/provisioning" label="Get Started"></p>
                )}
              </div>

              <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                transformOrigin={{ horizontal: "right", vertical: "top" }}
                anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                sx={{
                  left: 0,
                  right: 0,
                  width: "265px",
                }}
              >
                <OrganisationSelector />
              </Menu>
            </>
          )}
        </div>

        <div className={classes.loginGetStarted}>
          {!isAuthenticated ? (
            isMd ? (
              <HamBarger />
            ) : (
              <>
                <Button
                  id="qsLoginBtn"
                  style={{ marginRight: "10px" }}
                  color="primary"
                  className={classes.noOutline}
                  variant="text"
                  onClick={() => loginWithRedirect()}
                >
                  Login
                </Button>
                <Button
                  id="qsSignUpBtn"
                  style={{ borderRadius: "40px" }}
                  variant="contained-primary"
                  color="primary"
                  onClick={() =>
                    loginWithRedirect({
                      authorizationParams: {
                        screen_hint: "signup",
                      },
                    })
                  }
                >
                  Get Started
                </Button>
              </>
            )
          ) : (
            <UserMenu
              user={user}
              token={token}
              logoutWithRedirect={logoutWithRedirect}
            />
          )}
        </div>
        {/* </Container> */}
      </AppBar>
    </StylesProvider>
  );
}

const UserMenu = ({ user, token, logoutWithRedirect }) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const { employee } = useContext(AuthContext);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const [isSsoOpen, setIsSsoOpen] = useState(false);
  const handleSsoClose = () => {
    setIsSsoOpen(false);
  };
  const handleSSO = () => {
    setIsSsoOpen(true);
  };

  const profileTabWidth =
    !employee?.firstName &&
    !employee?.lastName &&
    !currentUserRole(employee?.roles)
      ? "100px"
      : "200px";

  return (
    <>
      <div>
        <Stack
          direction="row"
          spacing={2}
          className={[classes.userMenuContainer, classes.cursorPointer]}
          onClick={handleClick}
        >
          <IconButton
            size="small"
            aria-controls={open ? "account-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
          >
            <Avatar sx={{ width: 40, height: 40 }} src={user.picture} />
          </IconButton>

          <Stack direction="column" className={classes.hideEmployeeDetail}>
            <span className={classes.selectedOption}>
              {employee?.firstName}&nbsp;{employee?.lastName}
            </span>

            <span className={classes.userProfileRoleTitle}>
              {currentUserRole(employee?.roles)}
            </span>
          </Stack>

          <Stack>
            <img
              src={NavBarToggleIcon}
              alt="toggle-icon"
              style={{
                cursor: "pointer",
                transform: open ? "rotate(180deg)" : "rotate(0deg)",
                transition: "transform 0.1s ease",
              }}
            />
          </Stack>

          {/* #TODO: Add Arrow  */}
        </Stack>

        <Menu
          anchorEl={anchorEl}
          id="account-menu"
          open={open}
          onClose={handleClose}
          onClick={handleClose}
          transformOrigin={{ horizontal: "right", vertical: "top" }}
          anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
          sx={{
            width: "180px",
            left: 0,
            right: 0,
          }}
        >
          <Link to="/profile?view=details">
            <MenuItem onClick={handleClose} sx={{ width: profileTabWidth }}>
              <ListItemIcon>
                <PersonIcon />
              </ListItemIcon>
              <ListItemText style={{ color: "#000" }}>Profile</ListItemText>
            </MenuItem>
          </Link>

          <Divider />

          <MenuItem
            onClick={() => {
              handleSSO();
              handleClose();
            }}
            sx={{
              width: profileTabWidth,
            }}
          >
            <ListItemIcon>
              <KeyIcon />
            </ListItemIcon>
            <ListItemText style={{ color: "#000" }}>SSO</ListItemText>
          </MenuItem>

          <Divider />

          <MenuItem
            onClick={() => {
              logoutWithRedirect();
              handleClose();
            }}
            sx={{ width: profileTabWidth }}
          >
            <ListItemIcon>
              <img
                src={logout}
                alt="GoPassGo Networks, LLC"
                width="20"
                height="20"
              />
            </ListItemIcon>
            <ListItemText style={{ color: "#000" }}>Logout</ListItemText>
          </MenuItem>
        </Menu>
      </div>

      {isSsoOpen && (
        <SSO open={isSsoOpen} onClose={handleSsoClose} token={token}></SSO>
      )}
    </>
  );
};

const SSO = ({ onClose, open, token }) => {
  utils.assert(token != null, "Token required.  Unable to find recipient.");

  const { user } = useAuth0();
  const { setHeaders, setIsEmployeeLoading, setEmployee, setEmployeeService } =
    useContext(AuthContext);
  const [input, setInput] = useState({
    newToken: token,
    email: user?.email,
  });

  const handleCopy = (textToCopy) => {
    const clipboard = navigator.clipboard;
    clipboard
      .writeText(textToCopy)
      .then(() => {
        enqueueSnackbar("Copied to clipboard successfully!", {
          variant: "success",
        });
      })
      .catch((error) => {
        enqueueSnackbar(`Unable to write to clipboard. Error: ${error}`, {
          variant: "error",
        });
      });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setInput((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  };

  const handleUpdateToken = async (e) => {
    e.preventDefault();
    try {
      setIsEmployeeLoading(true);
      const newHeaders = getHeaders(input.newToken);
      await setHeaders(newHeaders);
      let employeeService = new EmployeeService(input.email, newHeaders);
      const employeeResponse = await employeeService.get(
        input.email,
        newHeaders
      );
      employeeService.currentEmployee = employeeResponse;

      await setEmployeeService(employeeService);
      await setEmployee(employeeResponse);
      if (
        window.location.pathname.includes("provisioning") &&
        employeeResponse?.organization
      ) {
        window.location.href("/");
      }
    } catch (error) {
      utils.log.error(error);
    } finally {
      setIsEmployeeLoading(false);
      onClose();
    }
  };

  return (
    <Dialog
      onClose={onClose}
      open={open}
      fullWidth
      PaperProps={{
        style: { maxWidth: "700px" },
      }}
    >
      <Form onSubmit={handleUpdateToken}>
        <DialogTitle>Single Sign-On</DialogTitle>
        <DialogContent>
          <DialogContentText style={{ marginBottom: "25px" }}>
            Copy the session token below (exactly) and paste it into the SSO
            login page.
          </DialogContentText>
          <Grid container spacing="20">
            <Grid item xs={12}>
              <TextField
                label="Email"
                fullWidth
                name="email"
                value={input.email}
                style={{ width: "100%", cursor: "pointer" }}
                onChange={handleChange}
                required
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <ContentCopyIcon
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          handleCopy(input?.email);
                        }}
                      />
                    </InputAdornment>
                  ),
                }}
              ></TextField>
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Session Token"
                fullWidth
                name="newToken"
                value={input.newToken}
                style={{ width: "100%" }}
                onChange={handleChange}
                required
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <ContentCopyIcon
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          handleCopy(input?.newToken);
                        }}
                      />
                    </InputAdornment>
                  ),
                }}
              ></TextField>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button type="submit">Set</Button>
          <Button onClick={onClose}>Close</Button>
        </DialogActions>
      </Form>
    </Dialog>
  );
};

export const OrganisationSelector = ({ menuItemClass }) => {
  const classes = useStyles();
  const { employeeService, employee, setUpEmployee } = useContext(AuthContext);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const handleSelectOrganisation = async (organisationCode) => {
    try {
      await employeeService.setOrganisation(organisationCode);
      await setUpEmployee(); // Fetch employee latest info
    } catch (error) {
      console.error("Error selecting organisation", error);
    }
  };
  return (
    <MenuList open={open} onClose={handleClose} onClick={handleClose}>
      {employee?.organizations?.length > 0 &&
        employee.organizations.map((org) => (
          <MenuItem
            onClick={() => handleSelectOrganisation(org.code)}
            key={org.code}
            sx={menuItemClass}
          >
            {employee?.organization?.code === org.code ? (
              <ListItemIcon>
                <RadioButtonCheckedIcon />
              </ListItemIcon>
            ) : (
              <ListItemIcon>
                <RadioButtonUncheckedIcon />
              </ListItemIcon>
            )}
            <ListItemText
              className={
                employee?.organization?.code === org?.code
                  ? classes.selectedItemColor
                  : classes.menuItemColor
              }

              // inset={employee?.organization?.code !== org.code}
            >
              {org.legalName}
            </ListItemText>
          </MenuItem>
        ))}
      <Link to="/provisioning" className={classes.addOrgButton}>
        <MenuItem>
          <ListItemIcon>
            <Add className={classes.addIcon} />
          </ListItemIcon>
          <ListItemText style={{ color: "#000" }}>
            Add organization
          </ListItemText>
        </MenuItem>
      </Link>
    </MenuList>
  );
};

const HamBarger = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const menuRef = useRef();

  const open = Boolean(isMenuOpen);

  const { loginWithRedirect } = useAuth0();
  const classes = useStyles();

  const handleClick = (event) => {
    setIsMenuOpen(true);
  };

  const handleClose = () => {
    setIsMenuOpen(false);
  };

  return (
    <>
      <div ref={menuRef}>
        {open ? (
          <MenuOpenIcon
            onClick={handleClose}
            sx={{
              height: "30px",
              width: "30px",
              color: "black",
              cursor: "pointer",
            }}
            key={"1"}
          />
        ) : (
          <MenuIcon
            onClick={handleClick}
            sx={{
              height: "30px",
              width: "30px",
              color: "black",
              cursor: "pointer",
            }}
            key={"0"}
          />
        )}
      </div>

      <Menu
        id="account-menu"
        anchorEl={menuRef.current}
        open={Boolean(isMenuOpen)}
        onClose={handleClose}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        sx={{
          top: 55,
          right: 0,
        }}
        key={"11"}
      >
        {NAVBAR.links.map((item, index) => {
          if (!item.condition) {
            return (
              <Box key={`div-${index}`}>
                <MenuItem key={`menuItem-${index}`} onClick={handleClose}>
                  <NavLink
                    to={item?.link}
                    key={`navlink-${index}`}
                    className={({ isActive }) =>
                      isActive ? classes.isActive : classes.isNotActive
                    }
                  >
                    {({ isActive }) => (
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <ListItemIcon
                          size="small"
                          aria-controls={open ? "account-menu" : undefined}
                          aria-haspopup="true"
                          aria-expanded={open ? "true" : undefined}
                        >
                          {item?.icon}
                        </ListItemIcon>
                        <ListItemText>
                          <MUIButton
                            className={`${classes.noOutlineButton} ${
                              isActive ? classes.isActiveButton : ""
                            }`}
                          >
                            {item.title}
                          </MUIButton>
                        </ListItemText>
                      </div>
                    )}
                    {/* </ListItemText> */}
                  </NavLink>
                </MenuItem>

                <Divider />
              </Box>
            );
          }
        })}
        <MenuItem onClick={() => loginWithRedirect()} key={"/login"}>
          <ListItemIcon>
            <LoginIcon />
          </ListItemIcon>
          <ListItemText
            style={{ color: "#041433", fontWeight: 500, padding: "0px 10px" }}
          >
            Login
          </ListItemText>
        </MenuItem>
        <Divider />
        <MenuItem
          onClick={() =>
            loginWithRedirect({
              authorizationParams: {
                screen_hint: "signup",
              },
            })
          }
          key={"/signup"}
        >
          <ListItemIcon>
            <img src={SignUpIcon} alt="signup-login" />
          </ListItemIcon>
          <ListItemText
            style={{ color: "#041433", fontWeight: 500, padding: "0px 10px" }}
          >
            Get Started
          </ListItemText>
        </MenuItem>
      </Menu>
    </>
  );
};
