import Button from "../Button";
import AddIcon from "@mui/icons-material/Add";
import {Tooltip} from "@mui/material";
import useMediaQuery from "../../hooks/useMediaQuery";

export const HeaderActions = ({ buttonTitle = "Add", onAdd ,disabled = false ,tooltipTitle = ""}) => {
  const {isMdDown,isSmDown} = useMediaQuery();
  return (
    <Tooltip title={tooltipTitle} arrow>
      <span>
      <Button
      size="medium"
      onClick={onAdd}
      startIcon={!isSmDown && <AddIcon />}
      variant="contained-primary"
      style={{
        borderRadius:"4px",
        background: disabled ? "#aaa":"",
        opacity: disabled? "0.8":"",
       }}
      disabled={disabled}
      tool
    >
      {isSmDown ? <AddIcon /> : isMdDown? "Add" : buttonTitle}
    </Button>
      </span>
    </Tooltip>
  );
};
