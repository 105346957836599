// BUG: 061423 Clicking on Preview does not show the updates to questions unless exiting survey and re-entering.  Need to update survey list with current survey details.
// TODO: Question validator component - Check question to make sure everything is okay by question type.
// TODO: Implement question drag and drop ordering in questions list using React Table (Tan Stack).
// TODO: In Question Detail, consider moving question type specific items to its own tab.
// TODO: 050723 When adding/updating question, prevent duplicate code from being entered.
// TODO: 052823 DEPRECATE FormUtility in favor of cache.js going forward.
/*
ISSUES

*/
import {Grid} from "@mui/material";
import { Editor } from "@tinymce/tinymce-react";
import { useSnackbar } from "notistack";
import {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import Button from "../../components/Button";
import Input from "../../components/Input/inputs";
import Table from "../../components/Table";
import Tabs, { Tab } from "../../components/Tabs";
import { typeLookup } from "../../services/config";
import { AuthContext } from "../../services/context";
import Debug from "../../services/debug";
import FormUtility from "../../services/form";
import * as utils from "../../services/utilities";
import { API_KEY_TINY_MCE } from "../../utils/env";
// import { SurveyContext } from "../surveys";
import { SurveyContext } from "../survey";
import Choices from "./choices";
import { Context, Selector as DependencySelector } from "./dependencySelector";
import QuestionTypeSelector from "./questionTypeSelector";
import { HeaderActions } from "../../components/HeaderActions";
import MUIDialog from "../../components/Modal";
import usePermissions from "../../hooks/usePermission";

const formUtility = new FormUtility();

export const QuestionContext = createContext();

// PURPOSE: Display a list of questions for the survey.
export const Questions = ({
  questions,
  setQuestions,
  setIsCommandBarVisible,
}) => {
  if (setIsCommandBarVisible) setIsCommandBarVisible(true);
  const { selectedSurvey, setSelectedSurvey  } = useContext(SurveyContext);
  const { surveyService, cache ,employee} = useContext(AuthContext);
  const [isAddModalOpen, setIsAddModalOpen] = useState(false); // Add dialog visibility
  const [detailOpen, setDetailOpen] = useState(false); // Detail dialog visibility
  const [selectedQuestion, setSelectedQuestion] = useState(null); // Question to edit

  const original = utils.cloneObject(selectedQuestion); // Original question for comparison

  const columns = [
    { Header: "Code", accessor: "code" },
    { Header: "Title", accessor: "title" },
    { Header: "Description", accessor: "description" },
    {
      Header: "Type",
      accessor: "type",
      Cell: ({ cell: value }) => {
        return value.value !== undefined
          ? surveyService.typeLookup(value.value).label
          : "- undefined -";
      },
    },
  ];
  //#endregion
  //#region Events
  const handleCancel = () => {
    setIsAddModalOpen(false);
    setDetailOpen(false);
    cache.set("name", selectedSurvey.name);
    cache.set("code", selectedSurvey.code);
  };
  const handleAddOpen = () => {
    setIsAddModalOpen(true);
  };
  const handleUpdateQuestion = (selectedQuestion) => {
    utils.log.event("Questions.handleUpdate() original", {
      original: original,
      update: selectedQuestion,
    });

    setIsAddModalOpen(false); // Close add dialog
    setDetailOpen(false); // Close detail dialog

    setSelectedSurvey((prev) => {
      const questions = prev.questions.map(
        // returns a new questions collection with updates
        (q) =>
          // Loop through each question and update the question that was changed.
          q.code === cache.get("code") // If question matches original code
            ? selectedQuestion // Update with new question
            : q // Otherwise ignore.
      );
      return { ...prev, questions: questions };
    });

    surveyService.surveyAddFunctions(selectedSurvey);
    surveyService.surveyDependencyFunctions(selectedSurvey);
  };

  const handleEdit = (question) => {
    setSelectedQuestion(question); // Set question to edit
    setDetailOpen(true); // Open detail dialog for editing
  };
  // const handleSetQuestion = (question) => {
  //   utils.log.stateChange("Questions.handleSetQuestion()", question);
  //   setSelectedQuestion(question);
  // };
  const handleQuestionDelete = (question) => {
    // Validate question type
    if (question == null || typeof question != "object")
      throw new Error("No question selected.");
    // Remove question from list

    // Remove question from survey
    const filtered = selectedSurvey.questions.filter(
      (q) => q.code !== question.code
    ); // NOTE: Assumes question is set.
    setSelectedSurvey((prev) => {
      return { ...selectedSurvey, questions: filtered };
    });
    // TODO: 070423 Remove any dependencies that reference this question or its choices.
    //handleUpdate(question);
    setIsAddModalOpen(false); // Close add dialog
    setDetailOpen(false); // Close detail dialog
  };
  const handleChoiceDelete = (questionCode, choice) => {
    // PURPOSE: Delete choice from question.
    utils.log.event(
      `Questions.handleChoiceDelete(questionCode: ${questionCode}, choice: ${choice.code})`
    );
    setSelectedSurvey((prev) => {
      const survey = { ...prev };
      const question = survey.questions.find((q) => q.code === questionCode);
      const choices = question.choices.filter((q) => q.code !== choice.code);
      question.choices = choices;
      return survey;
    });
    //handleUpdate(true);
  };

  // #endregion

  const questionContext = useMemo(
    () => ({
      questions,
      setQuestions,
      selectedQuestion,
      setSelectedQuestion,
    }),
    [questions, setQuestions, selectedQuestion, setSelectedQuestion]
  );

  const { canAddSurvey  } = usePermissions();
  const isQuestionLimitReached = !canAddSurvey(questions?.length ,employee?.plan);

  const headerAction = <HeaderActions 
  buttonTitle={"Add Question"}
  onAdd={handleAddOpen} 
  disabled={isQuestionLimitReached}
  tooltipTitle={isQuestionLimitReached ? "maxm-question limit is reached" : ""}
  
  />;
  return (
    <QuestionContext.Provider value={questionContext}>
      <Grid container spacing={2}>
        <Grid item container xs={12} justifyContent="flex-end">
          <Debug value={questions}></Debug>
        </Grid>
        <Grid item xs={12}>
          <Table
            columns={columns}
            data={questions?.length ? [...questions] : []}
            selected
            isDraggable={true}
            onSelected={(row) => handleEdit(row.original)}
            onDragEnd={(rows) => {
              setSelectedSurvey((prev) => {
                return {
                  ...prev,
                  questions: rows.map((row) => row.original),
                };
              });
              setQuestions(rows.map((row) => row.original));
            }}
            headerAction={headerAction}
          ></Table>
        </Grid>
      </Grid>

      {isAddModalOpen && <Add open onClose={() => setIsAddModalOpen(false)} />}
      {detailOpen && (
        <Overview
          open
          onUpdate={handleUpdateQuestion}
          onCancel={handleCancel}
          handleQuestionDelete={handleQuestionDelete}
          handleChoiceDelete={handleChoiceDelete}
          /* handleSetQuestionType={handleSetQuestionType} */
        ></Overview>
      )}
    </QuestionContext.Provider>
  );
};

const Overview = ({
  onUpdate,
  onCancel,
  handleQuestionDelete,
  handleChoiceDelete,
  /* handleSetQuestionType, */
}) => {
  // PURPOSE: Display question overview.
  const { selectedQuestion, questions, setQuestions, setSelectedQuestion } =
    useContext(QuestionContext);

  const { setSelectedSurvey } = useContext(SurveyContext);

  // #endregion
  // #region Initialize

  const { surveyService, cache, error } = useContext(AuthContext);
  /*eslint-disable-next-line*/
  const [inputs, setInputs] = useState({});
  const [questionType, setQuestionType] = useState(
    surveyService.typeLookup(selectedQuestion.type)
  );

  //const questionType = surveyService.typeLookup(question["type"]);

  utils.assert(
    questionType != null,
    `Question type(${questionType}) not found.`
  );

  const [codeErrorObject, setCodeErrorObject] = useState(null);
  //#endregion
  //#region Events

  const isValid = () => {
    const questionCodes = questions
      .map((item) => item.code)
      .filter((item) => item !== cache.get("code"));

    if (!utils.isUniqueValue(selectedQuestion?.code, questionCodes)) {
      // Code already used.
      error.setErrorObject("code", true, "Code already used.");
      setCodeErrorObject(error.getErrorObject("code"));
    } else {
      error.clearErrorObject("code");
    }
    if (error.hasErrors())
      utils.log.error(
        `Validation errors: ${error.hasErrors()}`,
        error.getErrors()
      );
    return !error.hasErrors();
  };

  useEffect(() => {
    cache.setDetails(selectedQuestion, this);
  }, []);

  const handleSetQuestionType = (type) => {
    const newQuestionType = surveyService.typeLookup(type);
    const newQuestion = newQuestionType.new();
    setQuestionType(newQuestionType);
    setQuestions((prevQuestions) => {
      const questions = [...prevQuestions];
      const index = questions.findIndex(
        (q) => q.code === selectedQuestion.code
      );
      // Copy common properties from old question to new question.
      const commonProperties = Object.keys(newQuestion)
        .filter((property) => property !== "type") // Exclude type property.
        .filter((property) => questions[index].hasOwnProperty(property));
      commonProperties.forEach((property) => {
        newQuestion[property] = questions[index][property];
      });
      //newQuestionType.reset(newQuestion);
      utils.log.stateChange(
        `Question(Overview).handleSetQuestionType(from: ${questions[index].type} to: ${newQuestionType.type})`,
        newQuestion
      );
      questions[index] = newQuestion;
      setSelectedQuestion(questions[index]);

      return questions;
    });
  };

  const handleUpdate = (e) => {
    // PURPOSE: Update question in survey
    e.preventDefault();
    if (isValid()) {
      onUpdate({ ...selectedQuestion, type: questionType.type }); //onUpdate(cache.getDetails());
    } else {
      utils.log.error("Question handleUpdate(invalid)");
      utils.log.error(error.getErrors());
    }
  };

  const updateQuestionTitle = (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          Update Question<Debug value={selectedQuestion}></Debug>
        </Grid>
        <Grid item xs={12}>
          <QuestionTypeSelector
            selectedQuestionType={selectedQuestion.type}
            handleSetQuestionType={handleSetQuestionType}
          ></QuestionTypeSelector>
        </Grid>
      </Grid>
    </>
  );

  const updateQuestionActionButtons = (
    <>
      <Button
        onClick={() => handleQuestionDelete(selectedQuestion)}
        tabIndex={-1}
      >
        Delete
      </Button>

      <Button onClick={onCancel} tabIndex={-1}>
        Cancel
      </Button>
      <Button onClick={handleUpdate}>Update</Button>
    </>
  );
  //#endregion
  return (
    <>
      <MUIDialog
        onClose={onCancel}
        open
        fullWidth
        maxWidth="md"
        title={updateQuestionTitle}
        actions={updateQuestionActionButtons}
        PaperProps={{ style: { maxHeight: "1000px", width: "90%" } }}
        onSubmit={onUpdate}
      >
        <Tabs>
          <Tab
            label="Details"
            component={
              <Details
                questionType={questionType}
                setInputs={setInputs}
                codeErrorObject={codeErrorObject}
              />
            }
          />
          <Tab
            label="Instructions"
            component={
              <Instructions
                question={selectedQuestion}
                codeErrorObject={codeErrorObject}
              />
            }
          />
          {questionType.showChoicesTab ? (
            <Tab
              label="Choices"
              component={
                <Choices
                  question={selectedQuestion}
                  questions={questions}
                  handleChoiceDelete={handleChoiceDelete}
                  onDragEnd={(updatedChoices) =>
                    setSelectedSurvey((prev) => {
                      const survey = { ...prev };
                      const question = survey.questions.find(
                        (q) => q.code === selectedQuestion.code
                      );
                      question.choices = updatedChoices;
                      return survey;
                    })
                  }
                />
              }
            />
          ) : (
            <Tab hidden></Tab>
          )}
          <Tab
            label="Preview"
            component={questionType?.render({
              question: selectedQuestion,
              questions: questions,
              setQuestions: setSelectedQuestion,
              preview: true,
            })}
          />
        </Tabs>
      </MUIDialog>
    </>
  );
};

const Details = ({ setInputs, codeErrorObject, questionType }) => {
  const {
    selectedQuestion,
    setSelectedQuestion,
    questions: allQuestions,
  } = useContext(QuestionContext);
  // TODO: 050723 Prevent duplicate code from being entered.
  // TODO: 051623 Validate form validation works by checking duplicate code.
  // PURPOSE: Display question details.
  utils.log.component("Question(Details)", selectedQuestion);
  // #region Initialize
  const { enqueueSnackbar } = useSnackbar();
  const context = Context(selectedQuestion);

  const handleChange = (e) => {
    setSelectedQuestion((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  // #endregion
  // #region Events

  // #endregion
  /* try { */
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={2}>
        <Input
          label="Code"
          fullWidth
          name="code"
          onChange={handleChange}
          defaultValue={selectedQuestion.code}
          error={codeErrorObject?.state}
          helperText={codeErrorObject?.message}
        />
      </Grid>
      <Grid item xs={12} sm={10}>
        <Input
          label="Title"
          fullWidth
          name="title"
          onChange={handleChange}
          defaultValue={selectedQuestion.title}
        />
      </Grid>
      <Grid item xs={12}>
        <Input
          label="Description"
          fullWidth
          multiline
          rows={5}
          name="description"
          onChange={handleChange}
          defaultValue={selectedQuestion.description}
          style={{ verticalAlign: "top" }}
          InputProps={{
            style: { height: "130px", verticalAlign: "top" },
          }}
        />
      </Grid>
      <Grid item xs={12}>
        {questionType?.edit({
          question: selectedQuestion,
          setInputs: setInputs,
          enqueueSnackbar: enqueueSnackbar,
          setQuestion: setSelectedQuestion,
        })}
      </Grid>
      <Grid item xs={12} width="100%">
        <DependencySelector
          selectedQuestion={selectedQuestion}
          setSelectedQuestion={setSelectedQuestion}
          context={context}
          questions={allQuestions}
        ></DependencySelector>
      </Grid>
    </Grid>
  );
  /*   } catch (e) {
    // TODO: Create error display component

    return (
      <>
        <p style={{ fontSize: "3", fontWeight: "bolder", color: "red" }}>
          ERROR:
        </p>
        <p style={{ color: "red" }}>{e.message}</p>
      </>
    );
  } */
};
const Instructions = (props) => {
  // #region Initialize
  utils.log.component("Question(Instructions) component", props.question);
  const { cache } = useContext(AuthContext);
  const editorRef = useRef(null);
  //const [editMode, setEditMode] = useState(false);
  const { question, codeErrorObject } = props;
  // #endregion
  // #region Events
  // #endregion

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        Recipient Instructions
        <Editor
          // TODO: 051323 Complete implementation of image insert
          apiKey={API_KEY_TINY_MCE}
          onInit={(evt, editor) => (editorRef.current = editor)}
          initialValue={question.instructions} //initialValue={cache.get("instructions")}
          onEditorChange={(content, editor) => {
            //cache.set("instructions", content);
            const event = {
              target: { name: "instructions", value: content },
            };
            /* formUtility.handleChange(event, setInputs); */
            cache.set(event);
          }}
          /* value={formUtility.getValue("instructions")} */
          error={codeErrorObject?.state}
          helperText={codeErrorObject?.message}
          init={{
            height: "430",
            menubar: false,
            branding: false,
            plugins: [
              "advlist autolink lists link image charmap print preview anchor",
              "searchreplace visualblocks code fullscreen",
              "insertdatetime media table paste code help wordcount textcolor",
            ],
            toolbar:
              "undo redo | formatselect | image | " +
              "bold italic | forecolor backcolor | alignleft aligncenter " +
              "alignright alignjustify | bullist numlist outdent indent | " +
              "removeformat | help",
            content_style:
              "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
          }}
        />
      </Grid>
    </Grid>
  );
};

const Add = ({ onClose, open }) => {
  const { selectedSurvey, setSelectedSurvey } = useContext(SurveyContext);
  const { setQuestions: setAllQuestions } = useContext(QuestionContext);

  const { surveyService, error } = useContext(AuthContext);
  const { enqueueSnackbar } = useSnackbar();

  const [inputs, setInputs] = useState({});
  const [codeErrorObject, setCodeErrorObject] = useState(null);
  const allQuestionTypes = surveyService.getQuestionTypes();
  const [question, setQuestion] = useState(allQuestionTypes?.at(0)?.new());

  const selectedQuestionType = useMemo(
    () => allQuestionTypes.find((q) => q.type === question.type),
    [question.type]
  );

  formUtility.setDetail(null);
  formUtility.setInputs(inputs);
  // #endregion
  //#region Events
  const handleSetQuestionType = (type) => {
    const questionType = surveyService.typeLookup(type);

    const questionNew = questionType.new(); // Reset fields
    setQuestion({
      ...questionNew,
      type: questionType.type,
    });
  };

  const isValid = () => {
    const qnCode = formUtility.getValue("code");
    const isQnAvilable = selectedSurvey?.questions?.find(
      (ele) => ele.code === qnCode
    );
    if (isQnAvilable) {
      error.setErrorObject("code", true, "Code already used.");
      setCodeErrorObject(error.getErrorObject("code"));
    } else {
      error.clearErrorObject("code");
    }

    if (error.hasErrors())
      utils.log.error(
        `Validation errors: ${error.hasErrors()}`,
        error.getErrors()
      );
    return !error.hasErrors();
  };
  const handleSubmit = async (event) => {
    // TODO: 050723 Check if code is unique;  ✅
    event.preventDefault();
    if (isValid()) {
      try {
        if (
          question?.type === "CategoryChoice" &&
          question?.categories.length === 0
        ) {
          return enqueueSnackbar("Category is required", { variant: "error" });
        }
        if (question?.type === "NumericInputRange") {
          if (
            question?.startMin > question?.startMax ||
            question?.endMin > question?.endMax
          ) {
            return enqueueSnackbar("Minimum should be less than Maximum", {
              variant: "error",
            });
          }
        }
        const stagedQuestion = {
          ...question,
          code: formUtility.getValue("code"),
          title: formUtility.getValue("title"),
          description: formUtility.getValue("description"),
          instructions: formUtility.getValue("instructions"),
        };
        setSelectedSurvey((prev) => ({
          ...prev,
          questions: [...prev.questions, stagedQuestion], // Update the selected survey questions list
        }));
        setAllQuestions((prev) => [...prev, stagedQuestion]); // Update the questions tables list

        let stagedSurvey = {
          ...selectedSurvey,
          questions: [...selectedSurvey.questions, stagedQuestion],
        };

        await surveyService.surveyPrepare(stagedSurvey);
        setSelectedSurvey(stagedSurvey);

        onClose();
      } catch (error) {
        utils.log.error(error);
        throw error;
      }
    } else {
      utils.log.error(error.getErrors());
    }
  };

  const addQuestionTitle = (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          Add question
        </Grid>
        <Grid item xs={12}>
          <QuestionTypeSelector
            selectedQuestionType={question.type}
            handleSetQuestionType={handleSetQuestionType}
            /* setInputs={setInputs} */
          ></QuestionTypeSelector>
        </Grid>
      </Grid>
    </>
  );

  return (
    <>
      <MUIDialog
        open={open}
        onClose={onClose}
        fullWidth
        title={addQuestionTitle}
        description={"Question add instructions..."}
        actions={
          <>
            <Button onClick={onClose}>Cancel</Button>
            <Button type="submit">Add</Button>
          </>
        }
        onSubmit={handleSubmit}
      >
        <Grid container spacing={2}>
          <Grid item xs={12} md={2}>
            <Input
              label="Code"
              fullWidth
              name="code"
              onChange={(e) => formUtility.handleChange(e, setInputs)}
              value={formUtility.getValue("code")}
              error={codeErrorObject?.state}
              helperText={codeErrorObject?.message}
              required="true"
            />
          </Grid>
          <Grid item xs={12} md={10}>
            <Input
              label="Title"
              fullWidth
              name="title"
              onChange={(e) => formUtility.handleChange(e, setInputs)}
              value={formUtility.getValue("title")}
              required="true"
            />
          </Grid>
          <Grid item xs={12}>
            <Input
              label="Description"
              fullWidth
              name="description"
              onChange={(e) => formUtility.handleChange(e, setInputs)}
              value={formUtility.getValue("description")}
            />
          </Grid>
          <Grid item xs={12}>
            <Input
              label="Instructions"
              fullWidth
              name="instructions"
              onChange={(e) => formUtility.handleChange(e, setInputs)}
              value={formUtility.getValue("instructions")}
            />
          </Grid>
          <Grid item container xs={12}>
            {selectedQuestionType?.edit({
              question: question,
              setInputs: setInputs,
              enqueueSnackbar: enqueueSnackbar,
              setQuestion,
            })}
          </Grid>
        </Grid>
      </MUIDialog>
    </>

  );
};

export function isQuestionType(unknown) {
  // PURPOSE: Check if type is a question type

  if ("type" in unknown && typeLookup(unknown.type) != null) return true;
  return false;
}

/*
FIXED
051223 - Update(button) not saving when clicked.
  STEPS:
    1. Open survey.
    2. Click on Questions tab.
    3. Click on a question.
    4. Change the title.
    5. Click on Update button.
    6. Click on the same question.
  EXPECTED:
    1. The title should be the updated value.
  CAUSE:
    1. The handleUpdate method passed from Questions to Overview component was in the format (e) => handleUpdate(e,original)
      which means wait until value are present.  What we want is to the function to Overview for it to set the original when validating.
    2. There state variable original was set to null in Questions and should not have been there.    3. 
  RESOLUTION:
    1. Deficit in difference between (e) => function and function(e) => function in Javascript.
  FIX:
    1. In Questions, changed Overview.onUpdate event from (e) => handleUpdate(e) to handleUpdate.
*/
