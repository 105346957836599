/* eslint-disable no-unused-vars */
// TODO: Add preview button to each row of the table.

import dayjs from "dayjs";
import PropTypes from "prop-types";
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";

import { DatePicker, TimePicker } from "antd";
import { DialogContent, Slider, useTheme } from "@mui/material";
import { useConfirm } from "material-ui-confirm";
import { enqueueSnackbar, useSnackbar } from "notistack";
import TimezoneSelect from "react-timezone-select";
import { Cell, Pie, PieChart, ResponsiveContainer } from "recharts";
import Button from "../../components/Button";
import Form from "../../components/Form";
import Grid from "../../components/Grid";
import Input from "../../components/Input/inputs";
import FormSelect from "../../components/Select";
import Table from "../../components/Table";
import Tabs, { Tab } from "../../components/Tabs";
import { NoticeEditor } from "../../components/Notice";
import * as config from "../../services/config";
import { AuthContext } from "../../services/context";
import Debug from "../../services/debug";
import * as utils from "../../services/utilities";
import { Questions } from "../survey-questions";
import * as dependencyUtil from "../survey-questions/dependency";
import { timeFormat } from "../survey-questions/timeInput";
import Emails from "../recipients/management/emails";
import Exemptions from "../recipients/management/exemptions";
import Find from "../recipients/management/find";
import Summary from "../recipients/management/summary";
import Upload from "../recipients/management/upload";
import Detail from "../recipients/detail";
import Chart from "react-apexcharts";
import { HeaderActions } from "../../components/HeaderActions";
import DashboardLayout from "../../components/DashboardLayout";
import { useSearchParams } from "react-router-dom";
import MUIDialog from "../../components/Modal";
import styles from "./style.module.css";
import usePermissions from "../../hooks/usePermission";
import useMediaQuery from "../../hooks/useMediaQuery";

const dateTimeFormat = `${config.defaults.DATE_STORAGE_FORMAT} h:mm A`; //const dateTimeFormat = `${config.defaults.DATE_STORAGE_FORMAT} ${timeFormat}`;
const { RangePicker } = DatePicker;

const columns = [
  { Header: "Code", accessor: "code" },
  { Header: "Name", accessor: "name" },
  {
    Header: "Start",
    accessor: "surveyActivePeriod_EffectiveDate",
    Cell: ({ row }) =>
      row.original.surveyActivePeriod_EffectiveDate
        ? dayjs(row.original.surveyActivePeriod_EffectiveDate).format(
            "YYYY-MM-DD HH:mm"
          )
        : "N/A",
  },
  {
    Header: "End",
    accessor: "surveyActivePeriod_ExpirationDate",
    Cell: ({ row }) =>
      row.original.surveyActivePeriod_ExpirationDate
        ? dayjs(row.original.surveyActivePeriod_ExpirationDate).format(
            "YYYY-MM-DD HH:mm"
          )
        : "N/A",
  },
];

export const SurveyContext = React.createContext({});

export default function Surveys() {
  const { surveyService, employee } = useContext(AuthContext);
  const [list, setList] = useState([]);
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [selectedSurvey, setSelectedSurvey] = useState(null);
  const [isLoading, setisLoading] = useState(true);
  const [searchParams] = useSearchParams();
  const confirm = useConfirm();
  const { canAddSurvey } = usePermissions();

  const fetchSurveys = useCallback(async () => {
    try {
      const list = await surveyService.getAll();
      setList(list);
    } catch (error) {
      utils.log.error(error);
    } finally {
      setisLoading(false);
    }
  }, [surveyService]);

  const fetchSurveyUsingCode = async (code) => {
    try {
      const survey = await surveyService.getByCode(code);
      let stagedSurvey = survey;
      if (!survey.emailSendTime) {
        stagedSurvey.emailSendTime = config.defaults.SURVEY_EMAIL_SEND_TIME;
      }
      setSelectedSurvey(stagedSurvey);
    } catch (error) {
      utils.log.error(`Error fetching survey (${code})`, error);
    } finally {
      setisLoading(false);
    }
  };

  const handleDeleteSurvey = useCallback(() => {
    confirm({
      description: `Confirm deletion of survey ${selectedSurvey.code}.`,
    })
      .then(async () => {
        try {
          await surveyService.remove(selectedSurvey.code);
          setList((prevList) =>
            prevList?.filter((list) => list?.code != selectedSurvey?.code)
          );
          setSelectedSurvey(null);
        } catch (error) {
          utils.log.error(error);
        }
      })
      .catch(() => utils.log.warn("Survey deletion cancelled."));
  }, [selectedSurvey]);

  // on row click
  const handleRowClick = (row) => {
    fetchSurveyUsingCode(row?.code);
  };

  useEffect(() => {
    fetchSurveys();
  }, []);

  useEffect(() => {
    const querySurveyCode = searchParams.get("surveyCode");
    if (querySurveyCode) {
      fetchSurveyUsingCode(querySurveyCode);
    }
  }, [searchParams]);

  const surveyContextValue = useMemo(() => {
    return {
      list,
      selectedSurvey,
      setSelectedSurvey,
      setList,
      handleDeleteSurvey,
      fetchSurveys,
    };
  }, [list, selectedSurvey, handleDeleteSurvey, fetchSurveys]);

  const isSurveyLimitReached = !canAddSurvey(list?.length, employee?.plan);

  const headerAction = (
    <HeaderActions
      buttonTitle={"Add Survey"}
      onAdd={setIsAddModalOpen}
      disabled={isSurveyLimitReached}
      tooltipTitle={isSurveyLimitReached ? "maxm survey limit is reached" : ""}
    />
  );

  return (
    <SurveyContext.Provider value={surveyContextValue}>
      <DashboardLayout
        title="Surveys"
        description="Easily Manage your surveys from a single place"
      >
        {isAddModalOpen && (
          <Add
            open={isAddModalOpen}
            onClose={() => setIsAddModalOpen(false)}
          ></Add>
        )}

        {selectedSurvey && <Overview />}

        {/* {list && ( */}
        <Table
          columns={columns}
          data={list}
          selected
          onSelected={(row) => handleRowClick(row.original)}
          headerAction={headerAction}
          isLoading={isLoading}
        ></Table>
        {/* )} */}
      </DashboardLayout>
    </SurveyContext.Provider>
  );
}

export const Validations = ({ validations, setIsCommandBarVisible }) => {
  if (setIsCommandBarVisible) setIsCommandBarVisible(true);
  const columns = [
    { Header: "Category", accessor: "category" },
    { Header: "Source", accessor: "source" },
    { Header: "Property", accessor: "property" },
    { Header: "Summary", accessor: "summary" },
  ];

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Table columns={columns} data={validations} />
        </Grid>
      </Grid>
    </>
  );
};

export const Overview = () => {
  const {
    list: surveys,
    selectedSurvey,
    setSelectedSurvey,
    handleDeleteSurvey,
    fetchSurveys,
  } = useContext(SurveyContext);

  const [preview, setPreview] = useState(false);
  const [codeErrorObject, setCodeErrorObject] = useState(null);
  const [questions, setQuestions] = useState([]);
  const [isConfirmValidationModalOpen, setIsConfirmValidationModalOpen] =
    useState(false);
  const [loading, setLoading] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [isCommandBarVisible, setIsCommandBarVisible] = useState(true);
  const { validations } = selectedSurvey;
  const { enqueueSnackbar } = useSnackbar();
  const { surveyService, cache, error } = useContext(AuthContext);
  const { isLgUp, isMdDown, isSmDown } = useMediaQuery();

  // PURPOSE: Validate survey.

  const surveyCodes = useMemo(() => {
    return surveys
      .map((item) => item.code)
      .filter((item) => item !== selectedSurvey.code);
  }, [surveys, selectedSurvey]);

  
  const isValid = useCallback(() => {
    if (!utils.isUniqueValue(cache.get("code"), surveyCodes)) {
      error.setErrorObject("code", true, "Code already used.");
      setCodeErrorObject(error.getErrorObject("code"));
    } else {
      error.clearErrorObject("code");
    }
    if (error.hasErrors())
      utils.log.error(
        `Validation errors: ${error.hasErrors()}`,
        error.getErrors()
      );
    return !error.hasErrors();
  }, [surveyCodes, cache]);

  // #endregion
  // #region Initialize

  // #endregion
  // #region Events
  // initial only
  useEffect(() => {
    utils.log.useEffect(
      `Surveys(Overview) - When survey change: Cache survey ${selectedSurvey.id}`
    );

    cache.setDetails({
      ...selectedSurvey,
      sampleGoalPct: selectedSurvey.sampleGoalPct || 30,
      skipValue: selectedSurvey.skipValue || 100,
    });
  }, [selectedSurvey]);

  const handleUpdateSurvey = async (
    event,
    ignoreWarning,
    isPublishFlag = false
  ) => {
    event.preventDefault();
    setLoading(isPublishFlag ? "publishing" : "updating");

    if (isValid()) {
      utils.log.info("Survey handleUpdate(valid)");
      cache.set("id", selectedSurvey.id); // inputs["id"] = survey.id;
      if (isPublishFlag) cache.set("isEnabled", selectedSurvey?.isEnabled);
      const requestBody = cache.getDetails();

      delete requestBody.validations;

      try {
        const query = {
          ignoreWarning: ignoreWarning,
        };

        const response = await surveyService.update(
          {
            ...requestBody,
            isEnabled: isPublishFlag
              ? !selectedSurvey?.isEnabled
              : selectedSurvey?.isEnabled,
          },
          query
        );

        setLoading(false);
        // Check for error
        if ("status" in response && response.status !== 200) {
          enqueueSnackbar(
            response?.detail ||
              "Something went wrong while updating the survey",
            { variant: "error" }
          );
          return;
        }

        if (response?.length > 0) {
          setLoading(false);
          setIsConfirmValidationModalOpen(!isConfirmValidationModalOpen);
          setSelectedSurvey({
            ...cache.getDetails(),
            validations: response,
            isEnabled: isPublishFlag
              ? !selectedSurvey?.isEnabled
              : selectedSurvey?.isEnabled,
          });
          return;
        }
        fetchSurveys();
        enqueueSnackbar("Success!", { variant: "success" });
        setSelectedSurvey(cache.getDetails());
        setLoading(false);
      } catch (error) {
        enqueueSnackbar(error?.message, { variant: "error" });
      }
      error.clearErrorObject(); // TODO: Test
      setCodeErrorObject(null);
    } else {
      utils.log.error(error);
    }
  };

  const handleCopy = () => {
    utils.log.event("Survey(Overview) handleCopy()");
  };

  const questionsReset = () => {
    utils.log.debug("questionsReset()", questions);
    setQuestions((prevQuestions) => {
      // PURPOSE: Reset questions.
      utils.log.info(
        "questionsReset(setQuestions) prevQuestions:",
        prevQuestions
      );
      const updatedQuestions = prevQuestions
        .filter((prevQuestion) => prevQuestion.hasOwnProperty("choices"))
        .map((prevQuestion) => {
          // Reset selections for choice based questions.
          const updatedChoices = prevQuestion.choices.map((choice) => {
            return { ...choice, isSelected: false };
          });
          // Re-establish dependency references.
          const dependency = dependencyUtil.resolveDependency(
            prevQuestions,
            prevQuestion.dependency
          );
          utils.log.info(
            `question(${prevQuestion.code}): dependency`,
            dependency
          );
          return {
            ...prevQuestion,
            choices: updatedChoices,
          };
        });
      updatedQuestions.forEach((question) => {
        surveyService.typeLookup(question.type).reset(question); // Reset user entered values.
      });
      dependencyUtil.resolveVisibilities(updatedQuestions);
      utils.log.stateChange(
        `questionsReset(questions.IsVisible): `,
        updatedQuestions.filter((q) => q.isVisible)
      );
      return updatedQuestions;
    });
  };

  const handlePreview = (isOpen) => {
    // OnInit: Reset questions.
    utils.log.event(
      `handlePreview(${isOpen}) ${isOpen ? "Open" : "Close"} clicked.`,
      questions
    );
    questionsReset();
    setPreview(isOpen);
  };

  useEffect(() => {
    setQuestions(selectedSurvey.questions);
  }, [questions]);

  const handleClose = () => {
    setSelectedSurvey(null);
    setSearchParams({
      view: "surveys",
    });
  };

  const surveyManagementActionButtons = (
    <Form
      spacing={2}
      style={{ width: "100%" }}
      onSubmit={(e) => {
        handleUpdateSurvey(e, false);
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={4} sm={2.4} md={2} lg={4}>
          <Button
            onClick={(event) => {
              handleUpdateSurvey(event, !selectedSurvey?.isEnabled, true);
            }}
            tabIndex={-1}
          >
            {loading === "publishing"
              ? "Loading..."
              : selectedSurvey?.isEnabled
              ? "UnPublish"
              : "Publish"}
          </Button>{" "}
        </Grid>
        {isLgUp ? (
          <Grid
            container
            spacing={4}
            item
            lg={8}
            justifyContent={{ lg: "flex-end" }}
          >
            <Grid item>
              <Button onClick={handleDeleteSurvey} tabIndex={-1}>
                Delete
              </Button>
            </Grid>
            <Grid item>
              <Button onClick={handleCopy} tabIndex={-1}>
                Copy
              </Button>
            </Grid>
            <Grid item>
              <Button onClick={() => handlePreview(true)} tabIndex={-1}>
                Preview
              </Button>{" "}
            </Grid>
            <Grid item>
              <Button onClick={handleClose} tabIndex={-1}>
                Cancel
              </Button>{" "}
            </Grid>
            <Grid item>
              <Button type="submit">
                {loading === "updating" ? "Loading" : "Update"}
              </Button>
            </Grid>
          </Grid>
        ) : (
          <>
            <Grid item xs={4} sm={1.8} md={2}>
              <Button onClick={handleDeleteSurvey} tabIndex={-1}>
                Delete
              </Button>
            </Grid>
            <Grid item xs={4} sm={1.8} md={2}>
              <Button onClick={handleCopy} tabIndex={-1}>
                Copy
              </Button>
            </Grid>
            <Grid item xs={4} sm={2} md={2}>
              <Button onClick={() => handlePreview(true)} tabIndex={-1}>
                Preview
              </Button>{" "}
            </Grid>
            <Grid item xs={4} sm={2} md={2}>
              <Button onClick={handleClose} tabIndex={-1}>
                Cancel
              </Button>{" "}
            </Grid>
            <Grid item xs={4} sm={2} md={2}>
              <Button type="submit">
                {loading === "updating" ? "Loading" : "Update"}
              </Button>
            </Grid>
          </>
        )}
      </Grid>
    </Form>
  );

  const validationErrorActionButtons = (
    <>
      <Button
        onClick={() => setIsConfirmValidationModalOpen(false)}
        tabIndex={-1}
      >
        Cancel
      </Button>{" "}
      <Button onClick={(e) => handleUpdateSurvey(e, true, false)} type="submit">
        {loading === "updating" ? "Loading" : "Update"}
      </Button>
    </>
  );
  // #endregion

  return (
    <>
      <MUIDialog
        open={Boolean(selectedSurvey)}
        onClose={handleClose}
        fullWidth
        maxWidth="lg"
        PaperProps={{
          style: {
            height: isMdDown ? "800px" : isSmDown ? "600px" : "800px",
          },
        }}
        title="Update Survey"
        description=" A survey is a series of questions that either have a choice or input
          that need to be answered by the user."
        actions={surveyManagementActionButtons}
      >
        <Tabs
          defaultActiveTab={
            searchParams.get("activeTab")?.includes("recipients") ? 2 : 0
          }
        >
          <Tab
            label="Details"
            component={
              <Details
                errorObject={codeErrorObject}
                setIsCommandBarVisible={setIsCommandBarVisible}
              />
            }
          />
          <Tab
            label="Questions"
            component={
              <Questions
                questions={questions}
                setQuestions={setQuestions}
                setIsCommandBarVisible={setIsCommandBarVisible}
              />
            }
          />
          <Tab
            label="Recipients"
            component={
              <Recipients
                survey={selectedSurvey}
                setSurvey={setSelectedSurvey}
                setIsCommandBarVisible={setIsCommandBarVisible}
              />
            }
          />
          {/* <Tab label="Metrics" component={<Metrics />} /> */}
          <Tab
            label="Notices"
            component={
              <Notices
                setIsCommandBarVisible={setIsCommandBarVisible}
                type="Survey"
                id={selectedSurvey?.id}
              />
            }
          />

          <Tab
            label={`Validations (${validations?.length || 0})`}
            component={
              <Validations
                validations={validations}
                setIsCommandBarVisible={setIsCommandBarVisible}
              />
            }
          />
        </Tabs>
        <Preview
          survey={selectedSurvey}
          questions={questions}
          setQuestions={setQuestions}
          open={preview}
          handleClose={() => {
            handlePreview(false);
          }}
        ></Preview>
      </MUIDialog>

      {/* Modal to show validations error message  */}
      <MUIDialog
        onClose={() => setIsConfirmValidationModalOpen(false)}
        open={Boolean(isConfirmValidationModalOpen)}
        fullWidth
        maxWidth="md"
        title="Are you sure,you want to update survey with validation error?"
        actions={validationErrorActionButtons}
      >
        <Validations validations={selectedSurvey?.validations || []} />
      </MUIDialog>
    </>
  );
};
const Notices = ({ setIsCommandBarVisible, type, id }) => {
  setIsCommandBarVisible(false);
  const sections = [
    "Survey_Recipient_Unavailable",
    "Survey_Recipient_BeforeStart",
    "Survey_Recipient_Completion",
    "Exemption_BeforeStart",
    "Exemption_Completion",
  ];
  return <NoticeEditor sections={sections} type={type} id={id} />;
};
export const Render = (props) => {
  const { isSmDown } = useMediaQuery();
  return (
    <>
      {props.questions.map((question, index) => {
        const rowStyle = {
          background: "#FFFFFF",
          border: "1px solid #E9E9E9",
          borderRadius: "8px",
          margin: "20px 0px",
          padding: "20px 30px",
          display: "flex",
          gap: isSmDown ? "4px" : "30px",
          flexDirection: isSmDown ? "column" : "row",
        };
        const questionType = config.typeLookup(question.type); // Get question type
        utils.log.section(
          `Render ${question.type} Question(${question?.code})})`,
          question
        );
        return questionType.render({
          index: index,
          rowStyle: rowStyle,
          question: question,
          questions: props.questions,
          setQuestions: props.setQuestions,
          survey: props.survey,
          preview: false,
          /*             setSurvey: props.setSurvey, */
          /* questionsResolveVisibility: props.questionsResolveVisibility, */
        });
      })}
    </>
  );
};

Overview.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

const Details = (props) => {
  utils.log.component("Survey.Details()", props);

  props.setIsCommandBarVisible(true);
  const { selectedSurvey, setSelectedSurvey } = useContext(SurveyContext);
  const { cache, surveyService } = useContext(AuthContext);
  const effectiveDate = dayjs(
    selectedSurvey?.surveyActivePeriod_EffectiveDate || dayjs()
  );
  const expirationDate = dayjs(
    selectedSurvey?.surveyActivePeriod_ExpirationDate || dayjs().add(1, "day")
  );
  const [skipValue, setSkipValue] = useState(cache.get("skipValue"));
  const [sampleGoalPct, setSampleGoalPct] = useState(
    cache.get("sampleGoalPct")
  ); // TODO: Survey.PercentRate property
  const [surveyMetrics, setSurveyMetrics] = useState([]);
  //const allTimeZones = utils.getTimeZones("America");
  //const [timeZones, setTimeZones] = useState(allTimeZones);
  //const timeZonesList = utils.toSelectItem(timeZones);

  const [selectedTimezone, setSelectedTimezone] = useState(
    selectedSurvey.timeZone ?? utils.getUserTimeZone() ?? {}
  );
  const [surveyDays, setSurveyDays] = useState(
    expirationDate?.diff(effectiveDate, "days")
  );

  // const surveyDays = expirationDate.diff(effectiveDate, "days");
  // #endregion
  // #region Events

  /* When we create survey => timezone && SurveyActive period is null,so When we land on detail page 
   then we update timezone and survey active period*/
  const initializeTimeZone = () => {
    if (!selectedSurvey.timeZone) {
      const userTimeZone = utils.getUserTimeZone();
      setSelectedSurvey((prev) => ({
        ...prev,
        timeZone: userTimeZone,
      }));
      setSelectedTimezone(userTimeZone);
    } else {
      setSelectedTimezone(selectedSurvey.timeZone);
    }
  };

  const initializeSurveyActivePeriod = () => {
    if (!selectedSurvey?.surveyActivePeriod_EffectiveDate) {
      setSelectedSurvey((prev) => ({
        ...prev,
        surveyActivePeriod_EffectiveDate: effectiveDate,
      }));
    }
    if (!selectedSurvey?.surveyActivePeriod_ExpirationDate) {
      setSelectedSurvey((prev) => ({
        ...prev,
        surveyActivePeriod_ExpirationDate: expirationDate,
      }));
    }
  };

  const getSurveyMetrics = async () => {
    try {
      const metricsData = await surveyService?.getSurveyMetrics(
        selectedSurvey?.code
      );
      setSurveyMetrics(metricsData);
    } catch (error) {
      utils.log.error("Failed to fetch the survey metrics");
    }
  };

  useEffect(() => {
    initializeTimeZone();
    initializeSurveyActivePeriod();
    getSurveyMetrics();
  }, []);

  const handleTimezoneChange = (e) => {
    const timeZone = e.value;
    setSelectedTimezone(timeZone);
    setSelectedSurvey((prev) => ({ ...prev, timeZone }));
  };
  const handleSurveyActivePeriodOkClick = (timeRange) => {
    if (timeRange === null) return;
    utils.log.event(`handleSurveyActivePeriodOkClick(timeRange)`, timeRange);
    utils.assert(timeRange.length === 2, "timeRange.length !== 2");
    utils.assert(
      dayjs(timeRange[0]).isValid(),
      `Start(timeRange[0]) is invalid time (expect ${dateTimeFormat})`
    );

    if (timeRange[1] != null) {
      utils.assert(
        dayjs(timeRange[1]).isValid(),
        `End(timeRange[1]) is invalid time (expect ${dateTimeFormat})`
      );
    }
    const effectiveDate = dayjs(timeRange[0]);
    let expirationDate = dayjs(timeRange[1]);
    const remainingDay = expirationDate?.diff(effectiveDate, "days");
    if (remainingDay <= 0) {
      return enqueueSnackbar(
        "Survey expiry date must be after the start date.",
        { variant: "error" }
      );
    }
    cache.set(
      "surveyActivePeriod_EffectiveDate",
      effectiveDate.format("YYYY-MM-DDTHH:mm:ssZ")
    );
    cache.set(
      "surveyActivePeriod_ExpirationDate",
      expirationDate.format("YYYY-MM-DDTHH:mm:ssZ")
    );
    setSurveyDays(expirationDate.diff(effectiveDate, "days"));
    utils.log.stateChange(
      "setSurveyDays(expirationDate.diff(effectiveDate, 'days'))",
      surveyDays
    );
  };

  const handleSampleGoalPctChange = (event, newValue) => {
    setSampleGoalPct(newValue);
    setSelectedSurvey((prev) => ({ ...prev, sampleGoalPct: newValue }));
  };

  const handleSampleGoalPctInputChange = (event) => {
    setSampleGoalPct(
      event.target.value === "" ? 0 : Number(event.target.value)
    );
    setSelectedSurvey((prev) => ({
      ...prev,
      sampleGoalPct: event.target.value,
    }));
  };

  const handleSkipValueChange = (e) => {
    setSkipValue(e.target.value);
    setSelectedSurvey((prev) => ({ ...prev, skipValue: e.target.value }));
  };
  const handleSampleGoalPctBlur = () => {
    if (sampleGoalPct < 0) {
      setSampleGoalPct(0);
    } else if (sampleGoalPct > 100) {
      setSampleGoalPct(100);
    }
  };

  const data = [
    { name: "Contacted", value: 200 },
    { name: "Started", value: 400 },
    { name: "Completed", value: 300 },
    { name: "Exempted", value: 300 },
  ];
  const COLORS = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042"];
  const RADIAN = Math.PI / 180;
  const renderCustomizedLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    percent,
    index,
    name,
  }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);
    //debugger;
    return (
      <text
        x={x}
        y={y}
        fill="black"
        textAnchor={x > cx ? "start" : "end"}
        dominantBaseline="central"
      >
        {`${(percent * 100).toFixed(0)}%`}
      </text>
    );
  };

  const [metricsModal, setMetricsModal] = useState({
    isOpen: false,
    type: "",
    data: [],
    options: {},
  });

  const handleOpenMetricsModal = (type, data, options) => {
    setMetricsModal({
      isOpen: true,
      type: type,
      data,
      options,
    });
  };

  const handleCloseMetricsModal = () => {
    setMetricsModal({
      isOpen: false,
      type: "",
      data: [],
      options: {},
    });
  };

  const handleClick = (metric) => {
    const dates = metric?.daily?.map((entry) => entry?.date);
    const totals = metric?.daily?.map((entry) => entry?.total);
    const newData = [
      {
        name: metric?.type,
        data: totals || [],
      },
    ];
    handleOpenMetricsModal(metric?.metric, newData, {
      chart: {
        id: "basic-bar",
        toolbar: {
          show: false,
        },
      },
      xaxis: {
        categories: dates,
      },
    });
  };
  const customStyles = {
    control: (provided) => ({
      ...provided,
      height: "56px",
    }),
  };

  return (
    <Grid container spacing={2} alignItems="flex-end">
      <Grid item xs={12} md={6}>
        <Input
          required
          label="Code"
          fullWidth
          name="code"
          onChange={(e) => cache.set(e)}
          defaultValue={cache.get("code")}
          error={props.errorObject?.state}
          helperText={props.errorObject?.message}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <Input
          required
          label="Name"
          fullWidth
          name="name"
          onChange={(e) => cache.set(e)}
          defaultValue={cache.get("name")}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        Survey Active Period ({surveyDays} days)
        <RangePicker
          style={{ height: "56px", width: "100%" }}
          size="large"
          showTime={{
            format: "h:mm A",
          }}
          defaultValue={[
            dayjs(effectiveDate, dateTimeFormat),
            dayjs(expirationDate, dateTimeFormat),
          ]}
          format={dateTimeFormat}
          onOk={handleSurveyActivePeriodOkClick}
          getPopupContainer={(triggerNode) => {
            // PURPOSE:  Used to position time picker relative to the question.
            return triggerNode.parentNode;
          }}
        />
      </Grid>
      <Grid item xs={12} md={6} style={{ zIndex: "100" }}>
        Location
        <TimezoneSelect
          styles={customStyles}
          height={"56px"}
          value={selectedTimezone}
          onChange={handleTimezoneChange}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <Input
          type="number"
          label="Skip Value"
          fullWidth
          value={skipValue}
          onChange={handleSkipValueChange}
        />
      </Grid>
      <Grid item xs={10} md={5}>
        Sampling Goal(%)
        <Slider
          value={typeof sampleGoalPct === "number" ? sampleGoalPct : 0}
          onChange={handleSampleGoalPctChange}
          aria-labelledby="input-slider"
        />
      </Grid>
      <Grid item xs={2} md={1}>
        <Input
          label="Goal(%)"
          value={sampleGoalPct}
          onChange={handleSampleGoalPctInputChange}
          onBlur={handleSampleGoalPctBlur}
          inputProps={{
            step: 10,
            min: 0,
            max: 100,
            type: "number",
            "aria-labelledby": "input-slider",
          }}
        />
      </Grid>

      <Grid item xs={12}>
        <div className={styles.detailsBarChartWrapper}>
          <div>Progress</div>

          <div className={styles.detailsBarChartContainer}>
            {surveyMetrics &&
              surveyMetrics?.map((metric) => (
                <div
                  style={{ cursor: "pointer" }}
                  onClick={() => handleClick(metric)}
                >
                  <Input
                    label={metric?.metric}
                    disabled
                    value={metric?.total}
                    style={{ pointerEvents: "none" }}
                  ></Input>
                </div>
              ))}
          </div>
        </div>
      </Grid>

      {/* <Grid item xs={5}>
        <ResponsiveContainer width="100%" height="100%">
          <PieChart width="350px" height="350px">
            <Pie
              data={data}
              cx="50%"
              cy="50%"
              labelLine={false}
              label={renderCustomizedLabel}
              outerRadius={80}
              fill="#8884d8"
              nameKey="name"
              dataKey="value"
            >
              {data.map((entry, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={COLORS[index % COLORS.length]}
                />
              ))}
            </Pie>
          </PieChart>
        </ResponsiveContainer>
      </Grid> */}

      {/* <MUIDialog
        open={metricsModal.isOpen}
        onClose={handleCloseMetricsModal}
        maxWidth="lg"
        fullWidth
      >
        <Chart
          options={metricsModal.options}
          series={metricsModal?.data || []}
          type="bar"
          width="100%"
          height="400px"
        />
      </MUIDialog> */}
    </Grid>
  );
};

const Add = (props) => {
  const { surveyService } = useContext(AuthContext);
  const { fetchSurveys } = useContext(SurveyContext);
  const { enqueueSnackbar } = useSnackbar();

  const [inputs, setInputs] = useState({
    code: "",
    name: "",
    surveyActivePeriod_EffectiveDate: "",
    surveyActivePeriod_ExpirationDate: "",
    description: "",
  });

  useEffect(() => {
    utils.log.event("Init: Add survey");
  }, []);

  const handleChange = (event) => {
    setInputs({
      ...inputs,
      [event.target.name]: event.target.value,
    });
  };

  const handleSubmit = async (event) => {
    utils.log.event("Survey(Add) handleSubmit()");
    event.preventDefault();
    try {
      if (
        dayjs(inputs.surveyActivePeriod_EffectiveDate).isAfter(
          dayjs(inputs.surveyActivePeriod_ExpirationDate)
        )
      ) {
        enqueueSnackbar("Start date cannot be after end date", {
          variant: "error",
          persist: true,
        });
        return;
      }

      const result = await surveyService.add(inputs);

      if (!result.ok) {
        const error = await result.json();
        enqueueSnackbar(error.detail, { variant: "error", persist: true });
      } else {
        // we update get all to get the new survey data
        fetchSurveys();
        enqueueSnackbar("Success!", { variant: "success" });
        props.onClose();
      }
    } catch (error) {
      utils.log.error("Error adding the survey", error);
    }
  };

  const getMinEndDate = () => {
    if (inputs.surveyActivePeriod_EffectiveDate) {
      return dayjs(inputs.surveyActivePeriod_EffectiveDate)
        .add(1, "day")
        .format("YYYY-MM-DD");
    }
    return dayjs().add(1, "day").format("YYYY-MM-DD");
  };

  return (
    <MUIDialog
      open={props.open}
      onClose={props.onClose}
      title="Add Survey"
      actions={
        <>
          <Button onClick={props.onClose}>Cancel</Button>
          <Button type="submit">Add</Button>
        </>
      }
      PaperProps={{ style: { height: "550px" } }}
    >
      <Form onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={4}>
            <Input
              required
              label="Code"
              fullWidth
              name="code"
              onChange={handleChange}
              value={inputs.code}
            />
          </Grid>
          <Grid item xs={12} md={8}>
            <Input
              required
              label="Name"
              fullWidth
              name="name"
              onChange={handleChange}
              value={inputs.name}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Input
              required
              label="Start Date"
              name="surveyActivePeriod_EffectiveDate"
              type="date"
              fullWidth
              onChange={handleChange}
              value={inputs.surveyActivePeriod_EffectiveDate}
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Input
              required
              label="End Date"
              name="surveyActivePeriod_ExpirationDate"
              type="date"
              fullWidth
              onChange={handleChange}
              value={inputs.surveyActivePeriod_ExpirationDate}
              inputProps={{
                min: getMinEndDate(),
              }}
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Input
              label="Description"
              name="description"
              fullWidth
              onChange={handleChange}
              value={inputs.description}
            />
          </Grid>
        </Grid>
      </Form>
    </MUIDialog>
  );
};

const Preview = (props) => {
  utils.log.component("Survey(Preview)");
  const [previewQuestions, setPreviewQuestions] = useState([]);

  useEffect(() => {
    if (props.questions) {
      setPreviewQuestions(props.survey.questions);
    }
  }, []);

  const formattedQuestions = useMemo(() => {
    if (previewQuestions.length > 0) {
      const temp = [...previewQuestions];
      dependencyUtil.resolveVisibilities(temp);
      return temp.filter((q) => q.isVisible);
    }
    return [];
  }, [previewQuestions]);

  utils.log.info(":: Preview question", {
    previewQuestions,
    formattedQuestions,
    props,
  });

  return (
    <MUIDialog
      open={props.open}
      onClose={props.handleClose}
      fullScreen
      maxWidth="xl"
    >
      <DialogContent style={{ padding: "50px" }}>
        <Grid container spacing={12}>
          <Grid item xs={12}>
            <Button onClick={props.handleClose}>Close</Button>
            <Debug value={props}></Debug>
          </Grid>
          <Grid item xs={12}>
            <Render
              survey={props.survey}
              questions={formattedQuestions}
              setQuestions={setPreviewQuestions}
            ></Render>
          </Grid>
        </Grid>
      </DialogContent>
    </MUIDialog>
  );
};
const Recipients = ({ survey, setSurvey, setIsCommandBarVisible }) => {
  utils.log.component("Survey(Recipients)");
  // #region Assertions
  utils.assert(survey != null, "Survey required.  Unable to find recipients.");

  // #endregion
  // #region Functions
  // #endregion
  // #region Initialize
  if (setIsCommandBarVisible) setIsCommandBarVisible(true);
  /* const effectiveDate = dayjs(survey.surveyActivePeriod_EffectiveDate);
  const expirationDate = dayjs(survey.surveyActivePeriod_ExpirationDate);
  const surveyDays = expirationDate.diff(effectiveDate, "days"); */

  const [emailSendTime, setEmailSendTime] = useState(
    survey.emailSendTime ?? config.defaults.SURVEY_EMAIL_SEND_TIME
  );
  const [openEmailRecipientsPreview, setOpenEmailRecipientsPreview] =
    useState(false);
  const [searchParams] = useSearchParams();

  useEffect(() => {
    if (!survey.emailSendTime) {
      setSurvey({
        ...survey,
        emailSendTime: config.defaults.SURVEY_EMAIL_SEND_TIME,
      });
    }
  }, [survey, setSurvey]);

  //const [openExemption, setOpenExemption] = useState(false);
  // #endregion
  // #region Events

  const handleCloseEmailRecipientsPreview = () => {
    setOpenEmailRecipientsPreview(false);
  };

  const handleEmailSendTimeChange = (e) => {
    //to handle case when we click on cross icon then we get nothing in e
    if (!e) return;
    const time = e?.format(timeFormat);
    // survey.emailSendTime = time;
    setSurvey({ ...survey, emailSendTime: time });
    setEmailSendTime(time);
    utils.log.stateChange(`setEmailSendTime(${time})`);
  };

  const handlePreviewRecipients = () => {
    utils.log.event("Survey(Recipients) handlePreviewRecipients()");
    setOpenEmailRecipientsPreview(true);
  };
  // #endregion

  return (
    <>
      <Grid container spacing={2} alignItems={"center"}>
        <Grid item xs={12}>
          <div style={{ fontSize: "10px" }}>
            <span style={{ color: "red" }}>*</span> Be sure to click UPDATE
            below to save settings.
          </div>
        </Grid>
        <Grid item xs={12} sm={8} md={10}>
          Email Schedule
          <br />
          <TimePicker
            use12Hours
            changeOnBlur
            size="large"
            format="h:mm A"
            style={{ height: "55px" }}
            defaultValue={dayjs(emailSendTime, timeFormat)}
            onChange={handleEmailSendTimeChange}
            //disabled={emailSendModeSelected !== "Automatic"}
            placeholder="Send time"
            getPopupContainer={(triggerNode) => {
              // PURPOSE:  Used to position time picker relative to the question.
              return triggerNode.parentNode;
            }}
            renderExtraFooter={() => (
              <div
                style={{
                  color: "blue",
                  marginLeft: "10px",
                  lineHeight: "18px",
                }}
              >
                Set daily time to send email invitations and reminders.
              </div>
            )}
          ></TimePicker>
        </Grid>
        <Grid item xs={12} sm={4} md={2}>
          <Button
            tabIndex={-1}
            // fullWidth
            variant="contained"
            className={styles.recipientButton}
            onClick={handlePreviewRecipients}
          >
            Preview
          </Button>
        </Grid>
      </Grid>
      <br></br>
      <Tabs
        defaultActiveTab={
          searchParams.get("activeTab")?.includes("upload") ? 3 : 0
        }
      >
        <Tab label="Summary" component={<Summary survey={survey}></Summary>} />
        <Tab label="Find" component={<Find survey={survey}></Find>} />
        <Tab
          label="Exemptions"
          component={
            <Exemptions
            /*             survey={survey}
            open={openExemption}
            onClose={handleClose} */
            ></Exemptions>
          }
        />
        <Tab label="Upload" component={<Upload survey={survey}></Upload>} />
        <Tab label="Emails" component={<Emails survey={survey}></Emails>}></Tab>
        {/* <Tab
          label="Add"
          tooltip="Add recipients to the survey"
          component={<RecipientAdd survey={survey}></RecipientAdd>}
        ></Tab> */}
      </Tabs>
      {openEmailRecipientsPreview && (
        <EmailRecipientsPreview
          survey={survey}
          isOpen={openEmailRecipientsPreview}
          onClose={handleCloseEmailRecipientsPreview}
          disabled={true}
        ></EmailRecipientsPreview>
      )}
    </>
  );
};
const Metrics = () => {
  utils.log.component("Survey(Metrics)");
  return (
    <>
      <h1>Metrics</h1>
      <Button>Export (CSV, Excel)</Button>
    </>
  );
};
export const SurveySelector = ({
  filter, // string (enabled, disabled, active) required
  selected, // string - required
  setSelected, // function - required
  label = "Survey", // string
  firstItemSelected = "",
}) => {
  utils.log.component("SurveySelector", { filter, selected, setSelected });
  // #region Assertions
  // #endregion
  // #region Functions
  const updateList = async () => {
    try {
      /*       const result = await surveyService.getAll(filter);
      const data = await result.json();
      if (data.status !== 500) {
        setList(utils.toSelectItem(data, "name", "code"));
        utils.log.stateChange("setList(data)", data);
      } */
      const list = await surveyService.getAll(filter);
      setList(utils.toSelectItem(list, "name", "code") || []);
      if (firstItemSelected) {
        setSelected(list[0]?.code);
      }
      utils.log.stateChange("setList(data)", list);
    } catch (error) {
      utils.log.error("Error updating list:", error);
      // Handle error
    }
  };
  // #endregion
  // #region Initialize
  const { surveyService } = useContext(AuthContext);
  const [list, setList] = useState(null);

  // #endregion
  // #region Events

  useEffect(() => {
    updateList();
  }, []);

  const handleChange = (event) => {
    event.preventDefault();
    setSelected(event.target.value);
    utils.log.stateChange(
      `SurveySelector.setSelected(event.target.value: ${event.target.value})`
    );
  };
  // #endregion

  return (
    <FormSelect
      label={label}
      data={list}
      fullWidth
      value={selected}
      onChange={(e) => {
        handleChange(e);
      }}
    ></FormSelect>
  );
};
const EmailRecipientsPreview = ({ survey, isOpen, onClose }) => {
  utils.log.component(`Survey(EmailRecipientsPreview) isOpen: ${isOpen}`);
  // #region Assertions
  utils.assert(survey != null, "Survey required.");
  // #endregion
  // #region Functions

  // #endregion
  // #region Initialize
  const { surveyService, isDevelopment } = useContext(AuthContext);
  const [recipients, setRecipients] = useState([]);
  const [filteredRecipients, setFilteredRecipients] = useState([]);

  const filters = utils.toSelectItem([`Invitation`, `Reminder`, `Followup`]);
  const [filterSelected, setFilterSelected] = useState(filters[0].value);
  const columns = [
    { Header: "Recipient", accessor: "fullName" },
    { Header: "Email", accessor: "email" },
    /* { Header: "Type", accessor: "messageTemplate" }, */
  ];

  const updateList = async () => {
    try {
      const recipients = await surveyService.recipientsContact(survey.code);
      setRecipients(recipients);
      const filter = filterSelected ?? filters[0].value;
      const filteredRecipients = recipients.filter(
        (item) => item.messageTemplate === filter
      );
      setFilteredRecipients(filteredRecipients);
      utils.log.stateChange(
        `setRecipients(recipients, ${filter})`,
        recipients,
        filteredRecipients
      );
    } catch (error) {
      // Handle any potential errors here
      utils.log.error("Error updating list:", error);
    }
  };

  const handleFilterChange = (e) => {
    utils.log.event("handleFilterChange(e)", e.target);
    const filter = e.target.value;
    setFilterSelected(filter);
    const filteredRecipients = recipients.filter(
      (item) => item.messageTemplate === filter
    );
    setFilteredRecipients(filteredRecipients);
    utils.log.stateChange(`setFilterSelected(${filter})`, filteredRecipients);
  };
  const handleSendEmails = async () => {
    utils.log.event("handleSendEmails()");
    await surveyService.recipientsContact(survey.code, false);
    onClose();
  };

  // #endregion
  // #region Events
  useEffect(() => {
    utils.log.useEffect("EmailRecipientsPreview", "Initialize");
    updateList();
  }, []);
  // #endregion

  return (
    <MUIDialog
      open={isOpen}
      onClose={onClose}
      title="Email Recipients(Preview)"
      actions={<Button onClick={onClose}>Close</Button>}
    >
      <Grid container spacing={2}>
        {isDevelopment && (
          <Grid item xs={12}>
            <div style={{ color: "red" }}>
              REMINDER: When in DEBUG mode, only a small subset of recipients
              are retrieved.
            </div>
          </Grid>
        )}
        <Grid item container xs={3} justifyContent="flex-end">
          <Button
            tabIndex={-1}
            fullWidth
            variant="contained"
            style={{ height: "55px" }}
            onClick={handleSendEmails}
          >
            Send NOW
          </Button>
        </Grid>

        <Grid item xs={12}>
          <Table
            columns={columns}
            data={filteredRecipients}
            selected
            caption={`Count: ${filteredRecipients.length} Total Recipients: ${recipients.length}`}
          ></Table>
        </Grid>
      </Grid>
    </MUIDialog>
  );
};

const RecipientAdd = ({ survey }) => {
  if (!survey) return null;
  return <Detail surveyCode={survey.code} recipient={{}} mode="add" />;
};

/* 
FIXED
051223 - Prior survey details displayed on edit.
  STEPS: 
    1. Select a survey to edit from list.
    2. Note details of survey (e.g. code), then click Cancel or Update button.
    3. Select a different survey to edit from list.
    4. The edit screen displays the details of the prior survey.
    5. Note details of survey (e.g. code), then click Cancel or Update button.
    6. Edit the same survey from step 3.
    7. The edit screen displays the correct details of the survey.
  EXPECTED: The edit screen displays the correct details of the survey.
  CAUSE: Moving setSurveyOpen(true) from getSurvey() to handleEdit() caused extra renders.
  RESOLUTION: Put setSurveyOpen(true) back in getSurvey().
  FIX: Moved setSurveyOpen(true) back to getSurvey().
*/
