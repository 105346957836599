import React, { useContext, useState, useMemo, useEffect } from "react";
import { AuthContext } from "../services/context";
import * as utils from "../services/utilities";
import { makeStyles } from "@mui/styles";
import { SideBar } from "../components/SideBar";
import { useSearchParams } from "react-router-dom";
import { MAIN_PAGE_TAB_LINKS } from "../utils/sideBarData";
import { Eula } from "../components/Notice";
import useMediaQuery from "../hooks/useMediaQuery";
// import { Viewer } from "../components/Notice";

const useStyles = makeStyles({
  root: {
    width: "100vw",
    top: "84px",
    minHeight: `calc(100vh - 84px)`,
    height: "auto",
  },
  body: {
    width: "full",
    height: "calc(100vh - 74px)",
    marginLeft: "240px",
    padding: "10px 40px",
    marginTop: "74px",
  },

  onSideBarOpen: {
    width: "full",
    height: "calc(100vh - 74px)",
    padding: "10px 40px",
    marginTop: "74px",
  },
  tabs: {
    display: "flex",
    alignItems: "center",
    fontWeight: "600",
    fontSize: "15px",
    gap: "14px",
    padding: "0px 15px",
    "& img": {
      height: "24px",
      width: "auto",
    },
  },
  title: {
    color: "#07070766 !important",
    fontWeight: 800,
    fontSize: "10px",
    marginTop: "10px",
    marginBottom: "5px",
    marginLeft: "15px",
  },
  tabContainer: {
    padding: "10px 0px",
    borderRadius: "8px",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#F4F7FB",
    },
  },
  selectedTabs: {
    background: "#8DC63F !important",
  },
  "@media (max-width:767px)": {
    body: {
      width: "100vw",
      marginLeft: "0px",
    },
  },
});

const Dashboard = ({ isSideBarOpen, handleToggleSideBar }) => {
  utils.log.component("Dashboard");

  // #region Initialize
  const classes = useStyles();
  const { employeeService } = useContext(AuthContext);
  const [searchParams, setSearchParams] = useSearchParams();
  const [activeTab, setActiveTab] = useState(
    searchParams.get("view") || MAIN_PAGE_TAB_LINKS[1].id
  );
  const {isMdDown} = useMediaQuery();
  // #endregion

  useEffect(() => {
    if (searchParams.get("view")) {
      setActiveTab(searchParams.get("view"));
    }
  }, [searchParams]);

  // #region Events

  const handleSetActiveTab = (tab) => {
    setActiveTab(tab?.id);
    setSearchParams({ view: tab?.id });
    if (isMdDown) {
      handleToggleSideBar();
    }
  };

  const tabs = useMemo(() => {
    return MAIN_PAGE_TAB_LINKS.filter(
      (tab) =>
        tab.roles.includes("DepartmentCoordinator") ||
        employeeService.hasRole(tab.roles[0])
    ).map((tab, index) => {
      if (tab?.title) {
        return (
          <div key={index} className={classes.title}>
            {tab?.title}
          </div>
        );
      }

      return (
        <div
          className={`${classes.tabContainer} ${
            activeTab === tab?.id ? classes.selectedTabs : ""
          }`}
          key={index}
          onClick={() => handleSetActiveTab(tab)}
        >
          <div
            key={tab?.id}
            className={`${classes.tabs} `}
          >
            {tab?.icon}
            <span className="tab-label">{tab?.label}</span>
          </div>
        </div>
      );
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTab, employeeService]);

  const ActiveComponent = useMemo(
    () => MAIN_PAGE_TAB_LINKS.find((tab) => tab.id === activeTab)?.component,
    [activeTab]
  );
  // #endregion

  return (
    <div className={classes.root}>
      {isSideBarOpen && <SideBar tabs={tabs} />}

      <div className={isSideBarOpen ? classes.body : classes.onSideBarOpen}>
        {ActiveComponent}
      </div>
      { <Eula />}
      {/* {<Viewer section="EULA" title="End User License Agreement" width="80%" />} */}
    </div>
  );
};

export default Dashboard;
