import Coordinators from "../views/coordinators";
import Worksites from "../views/worksites";
import Surveys from "../views/survey";
import { Transactions } from "../views/billing/transactions";
import { Overview as Recipients } from "../views/recipients/management";
import Progress from "../views/departments";
import WorksitesIcons from "../assets/icons/Worksites.svg";
import CoordinatorIcon from "../assets/icons/Coordinators.svg";
import DepartmentIcon from "../assets/icons/Departments.svg";
import HelpIcon from "../assets/icons/Help.svg";
import RecipientIcon from "../assets/icons/Recipient.svg";
import SurveyIcon from "../assets/icons/Surveys.svg";
import TransactionIcon from "../assets/icons/Transactions.svg";
import { Details, Organizations, Plan, Billing } from "../views/profile";
import { HelpSupport } from "../views/help-support";
export const MAIN_PAGE_TAB_LINKS = [
  {
    title: "CONFIGURE",
    roles: ["PrimaryCoordinator"],
  },
  {
    id: "coordinators",
    label: "Coordinators",
    icon: <img src={CoordinatorIcon} alt="Coordinators" />,
    component: <Coordinators />,
    roles: ["PrimaryCoordinator"],
  },
  {
    id: "worksites",
    label: "Worksites",
    icon: <img src={WorksitesIcons} alt="Worksites" />,
    component: <Worksites />,
    roles: ["PrimaryCoordinator"],
  },
  {
    id: "surveys",
    label: "Surveys",
    icon: <img src={SurveyIcon} alt="Surveys" />,
    component: <Surveys />,
    roles: ["PrimaryCoordinator"],
  },
  {
    id: "transactions",
    label: "Transactions",
    icon: <img src={TransactionIcon} alt="Transactions" />,
    component: <Transactions />,
    roles: ["PrimaryCoordinator"],
  },
  {
    title: "CONDUCT SURVEY",
    roles: ["PrimaryCoordinator", "DepartmentCoordinator"],
  },
  {
    id: "progress",
    label: "Progress",
    icon: <img src={DepartmentIcon} alt="Departments" />,
    component: <Progress />,
    roles: ["PrimaryCoordinator", "DepartmentCoordinator"],
  },

  {
    id: "recipients",
    label: "Recipients",
    icon: <img src={RecipientIcon} alt="Recipients" />,
    component: <Recipients />,
    roles: ["DepartmentCoordinator"],
  },

  {
    title: "MORE",
    roles: ["DepartmentCoordinator"],
  },
  {
    id: "help-support",
    label: "Help/Support",
    icon: <img src={HelpIcon} alt="Help/Support" />,
    component: <HelpSupport />,
    roles: ["PrimaryCoordinator", "DepartmentCoordinator"],
  },
];
export const PROFILE_PAGE_TAB_LINKS = [
  {
    id: "goBack",
    label: "Back to Dashboard",
    component: "",
    roles: ["Employee"],
  },
  {
    id: "details",
    label: "Details",
    component: <Details />,
    roles: ["Employee"],
  },
  {
    id: "organizations",
    label: "Organizations",
    component: <Organizations />,
    roles: ["Employee"],
  },
  {
    id: "plan",
    label: "Plan",
    component: <Plan />,
    roles: ["Employee"],
  },
  {
    id: "billing",
    label: "Billing",
    component: <Billing />,
    roles: ["Employee"],
  },
];
