import { useContext, useEffect, useState, useRef } from "react";
import * as utils from "../../services/utilities";
import orgPeople from "../../assets/organization_people.svg";
import GoPassGoIcon from "../../assets/GoPassGo.png";
import { Box, Divider, Menu, Stack } from "@mui/material";
import Item from "antd/es/list/Item";
import { OrganisationSelector } from "../NavBar";
import { AppContext } from "../../App";
import { AuthContext } from "../../services/context";
import styles from "./style.module.css";

export const SideBar = ({ tabs }) => {
  utils.log.component("SideBar");

  const sidebarRef = useRef();
  const { navBarToggler, setNavBarToggler } = useContext(AppContext);
  const { employee } = useContext(AuthContext);

  const handleClickOutside = (event) => {
    if (navBarToggler && sidebarRef.current && !sidebarRef.current.contains(event.target)) {
      setNavBarToggler(false);
    }
  };

  useEffect(() => {
    if (navBarToggler) {
      document.addEventListener("click", handleClickOutside);
    } else {
      document.removeEventListener("click", handleClickOutside);
    }

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [navBarToggler]);

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <div
      ref={sidebarRef}
      className={`${styles.sidebar} ${navBarToggler ? styles.sidebarSecondary : ""}`}
      onClick={() => {
        if (open) {
          setAnchorEl(null);
        }
      }}
    >
      <div className={styles.organisationSelector} onClick={handleClick}>
        <Stack direction={"row"} spacing={3} sx={{ cursor: "pointer", padding: "16px 10px" }}>
          <Item className={styles.listStyle}>
            <img src={orgPeople} alt="GoPassGo Networks, LLC" width="34" height="34" />
          </Item>

          <Item className={styles.listStyle}>
            <Stack direction={"column"}>
              <Box className={styles.textSwitch}>Switch Organization :</Box>
              <Box className={styles.selectedOption}>
                {employee?.organization?.legalName}
              </Box>
            </Stack>
          </Item>

          <Item className={styles.listStyle}>
            <Stack direction={"column"} spacing={-2}>
              <Box sx={{ fontWeight: "bold", color: "black" }}>^</Box>
              <Box
                sx={{
                  fontWeight: "bold",
                  transform: "rotate(-180deg)",
                  color: "black",
                }}
              >
                ^
              </Box>
            </Stack>
          </Item>
        </Stack>

        <Menu
          anchorEl={anchorEl}
          id="account-menu"
          open={open}
          onClose={handleClose}
          onClick={handleClose}
          transformOrigin={{ horizontal: "right", vertical: "top" }}
          anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
          sx={{ left: 0, right: 0, width: "265px" }}
        >
          <OrganisationSelector />
        </Menu>
      </div>

      <Divider className={styles.dividerClasess} />

      {tabs}

      <div className={styles.sidebarFooter}>
        <p className={styles.sidebarFooterTitle}>Powered By</p>
        <img src={GoPassGoIcon} alt="GoPassGo-Icon" height={"19px"} width={"133px"} />
      </div>
    </div>
  );
};
