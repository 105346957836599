import { Typography } from "antd/lib";
import styles from "./style.module.css";

const DashboardLayout = ({ title, description, actionButtons = null, children }) => {
  return (
    <>
      <div className={styles.headerContainer}>
        <div className={styles.headerInfo}>
          {title ? (
            <Typography className={styles.title} variant="h4">
              {title}
            </Typography>
          ) : null}
          {description ? (
            <Typography className={styles.description}>
              {description}
            </Typography>
          ) : null}
        </div>

        <div>
          {actionButtons && (
            <div className={styles.button}>{actionButtons}</div>
          )}
        </div>
      </div>

      {children}
    </>
  );
};

export default DashboardLayout;
