import { makeStyles } from "@mui/styles";
import heroImage from "../../assets/image copy.png";
import ProfileImage from "../../assets/profileImage.png";
import infoSection1Image from "../../assets/infoSection1.png";
import infoSection2Image from "../../assets/infoSection2.png";
import infoSection3Image from "../../assets/infoSection3.png";
import infoSection4Image from "../../assets/infoSectionr4.png";

const useStyles = makeStyles((theme) => ({
  pageContainer: {
    paddingTop: "150px",
    margin: "auto",
    background: "#ffffff",
  },
  heroSection: {
    padding: "0px 150px",
    display: "flex",
    justifyContent: "space-between",
    [theme.breakpoints.down("lg")]: {
      flexDirection: "column",
      gap: "30px",
      alignItems: "center",
      padding: "0px 25px",
    },
  },
  heroContentLeft: {
    paddingRight: "300px",
    [theme.breakpoints.down("lg")]: {
      padding: "0px",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
    },
  },

  // heroContentRight: {},
  heroSectionImage: {
    height: "300px",
  },
  profileInfo: {
    display: "flex",
    marginTop: "20px",
    gap: "15px",
  },
  quoteSection: {
    background: "#0066F9",
    height: "300px",
    color: "#ffffff",
    display: "flex",
    flexDirection: "column",
    gap: "30px",
    textAlign: "center",
    justifyContent: "center",
    marginTop: "100px",
  },
  quoteSectionTitle: {
    fontSize: "32px",
    [theme.breakpoints.down("md")]: {
      fontSize: "26px",
    },
  },
  quoteSectionDescp: {
    fontSize: "22px",
    [theme.breakpoints.down("md")]: {
      fontSize: "18px",
    },
  },
  contentSection: {
    fontSize: "20px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    gap: "60px",
    width: "40%",
    margin: "auto",
    padding: "100px 0px",

    [theme.breakpoints.down("lg")]: {
      width: "60%",
    },
    [theme.breakpoints.down("md")]: {
      width: "90%",
    },
  },
  contentBlock: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: "30px",
  },
  sectionTitle: {
    fontSize: "24px",
    fontWeight: "bold",
  },
  sectionImage: {
    width: "100%",
    maxWidth: "700px",
  },
  sectionText: {
    fontSize: "18px",
  },
}));

const TransportDemandManagementPage = () => {
  const classes = useStyles();

  return (
    <div className={classes.pageContainer}>
      {/* Hero Section */}
      <div className={classes.heroSection}>
        <div className={classes.heroContentLeft}>
          <div>
            <p>March 27, 2024 | 9 min Read</p>
            <h1>Transport Demand Management Commuter Solutions</h1>
            <div className={classes.profileInfo}>
              <div>
                <img
                  src={ProfileImage}
                  height={"50"}
                  width={"50"}
                  alt="Profile"
                />
              </div>
              <div>
                <div>Matt Campbell</div>
                <div>Active</div>
              </div>
            </div>
          </div>
        </div>
        <div className={classes.heroContentRight}>
          <img
            src={heroImage}
            alt="Hero"
            className={classes.heroSectionImage}
          />
        </div>
      </div>

      {/* Quote Section */}
      <div className={classes.quoteSection}>
        <div className={classes.quoteSectionTitle}>
          “Transport Demand Management allows cities to optimize existing
          infrastructure and reduce congestion without the heavy costs
          associated with road expansion.”
        </div>
        <div className={classes.quoteSectionDescp}>Jane Doe, Urban Planner</div>
      </div>

      {/* Content Sections */}
      <div className={classes.contentSection}>
        <div className={classes.contentBlock}>
          <h1 className={classes.sectionTitle}>Corporate Commuter Solutions</h1>
          <img
            src={infoSection1Image}
            className={classes.sectionImage}
            alt="Info Section 1"
          />
          <p className={classes.sectionText}>
            Our cutting-edge, cloud-based platform is designed to simplify the
            way you manage and engage with both commuters and employers. With
            this comprehensive solution, you can easily centralize all aspects
            of commuter outreach and transportation management in one place.
          </p>
          <p className={classes.sectionText}>
            Whether you're streamlining communication, organizing commuter
            programs, or tracking ridership data, our CRM solution provides the
            tools needed to enhance the daily commuting experience.
          </p>
        </div>

        <div className={classes.contentBlock}>
          <h1 className={classes.sectionTitle}>
            The Ultimate Commuter Relationship Management System
          </h1>
          <p className={classes.sectionText}>
            Our enterprise platform empowers organizations to efficiently manage
            their outreach and commuter programs through a single, unified
            system. Designed to be a comprehensive solution, it enables the
            collection, management, and reporting of data on accounts,
            employers, property managers, commuters, and programs—all in one
            place.
          </p>
          <img
            src={infoSection2Image}
            className={classes.sectionImage}
            alt="Info Section 2"
          />
        </div>

        <div className={classes.contentBlock}>
          <h1 className={classes.sectionTitle}>
            Reduce Single-Occupancy Vehicles on the Road
          </h1>
          <p className={classes.sectionText}>
            Our solution empowers companies, employees, and communities to
            collaborate in implementing effective commuting programs that reduce
            the number of single-occupancy vehicles (SOV) on the roads.
          </p>
          <img
            src={infoSection3Image}
            className={classes.sectionImage}
            alt="Info Section 3"
          />
        </div>

        <div className={classes.contentBlock}>
          <h1 className={classes.sectionTitle}>
            All Your Commute and Employer Data in One Place
          </h1>
          <p className={classes.sectionText}>
            Our platform centralizes all your commute and employer data,
            providing a single, integrated solution for collecting and reporting
            information.
          </p>
          <img
            src={infoSection4Image}
            className={classes.sectionImage}
            alt="Info Section 4"
          />
        </div>
      </div>
    </div>
  );
};

export default TransportDemandManagementPage;
