import { makeStyles } from "@mui/styles";
import backgroundGradient from "../../assets/backgroundGredient.svg";
import { useNavigate } from "react-router-dom";
import { blogData } from "./data";

const useBlogStyles = makeStyles((theme) => ({
  blogWrapper: {
    padding: "100px 0px",
  },
  blogContent: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  blogTitle: {
    marginTop: "20px",
  },
  blogSubheading: {
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
    },
  },
  blogCardsWrapper: {
    width: "100%",
  },
  blogBackgroundSection: {
    padding: theme.spacing(7),
    backgroundImage: `url(${backgroundGradient})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
  },
  blogCardsContainer: {
    display: "flex",
    gap: "50px",
    justifyContent: "center",
    padding: "0 100px",
  },
  blogCardContainer: {
    width: "600px",
  },
}));

const useStyles = makeStyles((theme) => ({
  cardWrapper: {
    cursor: "pointer",
  },
  cardTitle: {
    [theme.breakpoints.down("md")]: {
      fontSize: "16px",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "12px",
    },
  },
  cardImage: {
    borderRadius: "10px 10px 0px 0px",
  },
  cardInfo: {
    padding: "25px",
    boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
    borderRadius: "0px 0px 10px 10px",
    [theme.breakpoints.down("md")]: {
      padding: "15px",
      fontSize: "16px",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "10px",
      fontSize: "12px",
    },
  },
  cardLearnMore: {
    color: "#007BFF",
  },
}));

export const Blog = () => {
  const classes = useBlogStyles();
  const navigate = useNavigate();
  const blogClasses = useStyles();

  const handleCardClick = (item) => {
    navigate(`/blog/${item?.slug}`);
  };

  return (
    <div className={classes.blogWrapper}>
      <div className={classes.blogContent}>
        <h2 className={classes.blogTitle}>Blogs</h2>
        <p className={classes.blogSubheading}>
          The latest news and analysis on the future of commuting
        </p>
        <div className={classes.blogCardsWrapper}>
          <div className={classes.blogBackgroundSection}>
            <div className={classes.blogCardsContainer}>
              {blogData?.map((item) => (
                <div className={classes.blogCardContainer} key={item?.slug}>
                  <div
                    className={blogClasses.cardWrapper}
                    onClick={() => handleCardClick(item)}
                  >
                    <img
                      width={"100%"}
                      src={item.image}
                      className={blogClasses.cardImage}
                      alt="blog-card"
                    />
                    <div className={blogClasses.cardInfo}>
                      <h5 className={blogClasses.cardTitle}>{item?.title}</h5>
                      <span className={blogClasses.cardLearnMore}>
                        Learn More
                      </span>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
